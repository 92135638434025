
	import _prop from 'vue-types';

	export default {
		name: 'catalog-nav-tertiary',
		components: {},
		props: {
			title: _prop.string,
			columns: _prop.array,
			active: _prop.bool.def(false),
		},
		data: (vm) => ({}),
		computed: {},
		watch: {},
		methods: {
			getComponent(item) {
				return item.link === this.$route.path ? 'span' : 'a';
			},

			getComponentBind(item) {
				return item.link === this.$route.path ? {} : { href: item.link };
			},

			isCurrentItem(item) {
				return item.link === this.$route.path;
			},
		},
	};
