
	import _prop from 'vue-types';
	import CommonCrossButton from '~components/_common/common-cross-button/common-cross-button.vue';
	import ModalMobile from '~components/_modals/_modal-mobile/modal-mobile.vue';
	import { mapGetters } from 'vuex';
	import { mapStores } from 'pinia';
	import { useEntityStore, useGlobalStore } from '~stores/index.js';
	import DateTimePicker from '~components/date-time-picker/date-time-picker.vue';
	import AppSelectorCities from './_app-selector-cities/app-selector-cities.vue';

	export default {
		name: 'app-selector',
		components: { CommonCrossButton, ModalMobile, DateTimePicker, AppSelectorCities },
		props: {
			small: _prop.bool.def(false),
			dropdownClass: _prop.string,
			titleClass: _prop.string,
			enlarged: _prop.bool.def(false),
		},
		data: (vm) => ({
			isCityActive: false,
			isDateActive: false,
		}),
		computed: {
			...mapGetters('layout', ['cities']),
			...mapStores(useEntityStore, useGlobalStore),

			isCityActiveDesktop: {
				get() { return this.isCityActive && this.$screen.desktop; },
				set(v) { this.isCityActive = v; },
			},

			isCityActiveMobile: {
				get() { return this.isCityActive && !this.$screen.desktop; },
				set(v) { this.isCityActive = v; },
			},

			isDateActiveDesktop: {
				get() { return this.isDateActive && this.$screen.desktop; },
				set(v) { this.isCityActive = v; },
			},

			isDateActiveMobile: {
				get() { return this.isDateActive && !this.$screen.desktop; },
				set(v) { this.isCityActive = v; },
			},

			cDates() {
				return this.globalStore.dates.map(i => ({
					...i,
					active: i.date === this.globalStore.activeDate,
				}));
			},
			cCityName() {
				return this.cities.find(c => c.id === this.globalStore.cityId)?.title || 'UNKNOWN';
			},
		},
		watch: {},
		methods: {
			onEsc(e) {
				if (e.code !== 'Escape') return;
				this.isCityActive = false;
				this.isDateActive = false;
			},

			onDateApply() {
				this.globalStore.applyDate(true);
				this.isDateActive = false;
			},

			onDateClose() {
				!this.$screen.desktop && this.globalStore.resetStagedDate();
				this.$screen.desktop && this.globalStore.applyDate(true);
				this.isDateActive = false;
			},

			onClickOutside() {
				if (!this.$screen.desktop) return;
				this.isCityActive = false;
				this.isDateActive = false;
			},
		},
		mounted() {
			window.addEventListener('keydown', this.onEsc);
		},
		beforeDestroy() {
			window.removeEventListener('keydown', this.onEsc);
		},
	};
