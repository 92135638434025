
	import _prop from 'vue-types';

	export default {
		name: 'safe-nuxt-link',
		inheritAttrs: false,
		components: {},
		props: {
			to: _prop.string,
			preferableTag: _prop.string,
			useTargetBlank: _prop.bool.def(false),
			fallback: _prop.string.def('button'),
		},
		data: () => ({}),
		computed: {
			cComponent() {
				if (!this.to) return this.fallback;
				if (this.preferableTag) return this.preferableTag;
				return 'a';
			},

			cBind() {
				const blank = this.useTargetBlank ? { target: '_blank', rel: 'noopener noreferrer' } : {};
				const buttonProps = this.fallback === 'button' ? { type: 'button' } : {};
				return this.cComponent === 'a'
					? { href: this.to, ...blank }
					: { ...buttonProps };
			},
		},
		methods: {},
	};
