
	import _prop from 'vue-types';
	import CommonCrossButton from '~components/_common/common-cross-button/common-cross-button.vue';
	import ModalMobile from '~components/_modals/_modal-mobile/modal-mobile.vue';
	import DateTimePickerInner from './_date-time-picker-inner/date-time-picker-inner.vue';

	export default {
		name: 'date-time-picker',
		components: { CommonCrossButton, DateTimePickerInner, ModalMobile },
		props: {
			value: _prop.bool.def(false),
			dates: _prop.array,
			times: _prop.array,
		},
		emits: [
			'input',
			'date-click',
			'time-click',
			'apply',
			'close',
			'click-outside',
		],
		data: () => ({}),
		computed: {
			isSingleColumn() {
				return (this.dates.length === 0 || this.times.length === 0);
			},

			cModalTitle() {
				if (this.dates.length && !this.times.length) return this.$t('delivery-date');
				return this.$t('delivery-date-and-time');
			},

			cModalAlign() {
				return this.isSingleColumn ? 'center' : 'left';
			},

			isDesktopActive: {
				get() {
					return this.value && this.$screen.desktop;
				},

				set(v) {
					this.$emit('input', v);
				},
			},

			isMobileActive: {
				get() {
					return this.value && !this.$screen.desktop;
				},

				set(v) {
					this.$emit('input', v);
				},
			},
		},
		watch: {
			value: {
				handler(isActive) {
					const method = isActive ? 'addEventListener' : 'removeEventListener';
					document[method]('keydown', this.onKeydown);
				},
			},
		},
		methods: {
			onKeydown(e) {
				if (e.code !== 'Escape') return;
				this.$emit('close');
			},
		},
	};
