import { sleep } from '@morev/utils';
import Vue from 'vue';

const ENDPOINT_ITEMS = '/cart-items/';
const ENDPOINT_EXTRA = '/cart-extra/';
const ENDPOINT_CARDS = '/cart-cards/';
const ENDPOINT_PRICE_MODIFICATIONS = '/cart-price-modifications/';
const ENDPOINT_PROMOCODE = '/cart-promocode/';
const ENDPOINT_POINTS = '/cart-points/';
const ENDPOINT_SETTINGS = '/cart-settings/';

const PENDING_PROP = 'pending';
const PARTNERS_PROP = 'partners';
const ITEMS_PROP = 'items';
const EXTRA_PROP = 'extra';
const CARDS_PROP = 'cards';
const PROMOCODE_PROP = 'promocode';
const DISCOUNT_PROP = 'discount';
const POINTS_PROP = 'points';
const DELIVERY_PROP = 'delivery';
const SETTINGS_PROP = 'settings';

const CRUTCH_PROP = '_crutch';

export const state = () => ({
	[SETTINGS_PROP]: {},
	[PENDING_PROP]: {},

	[PARTNERS_PROP]: [],

	[ITEMS_PROP]: [],
	[EXTRA_PROP]: [],
	[CARDS_PROP]: [],

	[PROMOCODE_PROP]: '',
	[DISCOUNT_PROP]: 0,
	[POINTS_PROP]: 0,
	[DELIVERY_PROP]: undefined,

	// App-formulate-input type="number" не обновляется при изменении quantity.
	// Поэтому здесь костыль - при каждом изменении букетов или доп.товаров увеличивается счётчик,
	// сам инпут имеет :key, завязанный на этот счётчик, чтобы обновлять UI
	[CRUTCH_PROP]: {},
});

export const mutations = {
	SET_PENDING: (state, [key, value]) => Vue.set(state[PENDING_PROP], key, value),
	SET_ITEM_PENDING: (state, [key, value]) => Vue.set(state[PENDING_PROP], `ITEM_${key}`, value),

	SET_SETTINGS: (state, settings) => state[SETTINGS_PROP] = settings,

	SET_DISCOUNT: (state, value) => state[DISCOUNT_PROP] = value,
	SET_POINTS: (state, value) => state[POINTS_PROP] = value,

	SET_PROMOCODE: (state, value) => state[PROMOCODE_PROP] = value,

	SET_PARTNERS: (state, value) => state[PARTNERS_PROP] = value,
	SET_ITEMS: (state, value) => state[ITEMS_PROP] = value,
	SET_EXTRA: (state, value) => state[EXTRA_PROP] = value,
	SET_CARDS: (state, value) => state[CARDS_PROP] = value,

	SET_QUANTITY: (state, { type, id, quantity }) => {
		const whereToFind = type === 'product' ? state[ITEMS_PROP] : state[EXTRA_PROP];
		const item = whereToFind.find(i => i.id === id);
		item.quantity = quantity;
	},

	SET_DELIVERY_PRICE: (state, price) => state[DELIVERY_PROP] = price,

	SET_CRUTCH: (state, items) => {
		items.map(i => i.id).forEach(id => {
			state[CRUTCH_PROP][id] = state[CRUTCH_PROP][id]
				? state[CRUTCH_PROP][id] + 1
				: 1;
		});
	},
};

export const actions = {
	async init({ commit }) {
		const { items, extra } = await this.$api.get({
			[`[items] ${ENDPOINT_ITEMS}`]: {},
			[`[extra] ${ENDPOINT_EXTRA}`]: {},
		});
		commit('SET_ITEMS', items);
		commit('SET_EXTRA', extra);
	},

	async loadCart({ commit, dispatch }, ctx) {
		const cart = await this.$api.get({
			[`[settings] ${ENDPOINT_SETTINGS}`]: {},
			[`[items] ${ENDPOINT_ITEMS}`]: {},
			[`[extra] ${ENDPOINT_EXTRA}`]: {},
			[`[cards] ${ENDPOINT_CARDS}`]: {},
			[`[priceModifications] ${ENDPOINT_PRICE_MODIFICATIONS}`]: {},
			[`[promocode] ${ENDPOINT_PROMOCODE}`]: {},
		});

		const partnersId = cart.items?.reduce((acc, { partnerId }) => {
			if (!acc.includes(partnerId)) acc.push(partnerId);
			return acc;
		}, []) || [];

		const extraProductsQuery = partnersId.reduce((acc, id) => {
			acc[`[${id}] /products-extra-list`] = { partner_id: id };
			return acc;
		}, {});

		let [partners, partnersExtraProducts] = await Promise.all([
			this.$api.get('/partners-list', { id: partnersId.join(',') }),
			this.$api.get(extraProductsQuery),
		]);

		partners = partners?.map(partner => ({
			...partner,
			products: partnersExtraProducts[partner.id],
		})) || [];

		dispatch('setPriceModifications', cart.priceModifications);

		commit('SET_PARTNERS', partners);
		commit('SET_SETTINGS', cart.settings);
		commit('SET_ITEMS', cart.items);
		commit('SET_EXTRA', cart.extra);
		commit('SET_CARDS', cart.cards);
		commit('SET_PROMOCODE', cart.promocode?.code || '');

		commit('SET_CRUTCH', [...cart.items, ...cart.extra]);
	},

	setPriceModifications({ commit }, priceModifications) {
		if (!priceModifications) return;

		commit('SET_DISCOUNT', priceModifications.discount);
		commit('SET_POINTS', priceModifications.points);
	},

	async applyPromocode({ state, commit, dispatch }, code) {
		const PENDING_KEY = 'promocode';

		commit('SET_PENDING', [PENDING_KEY, true]);
		const response = await this.$api.post(ENDPOINT_PROMOCODE, { code });

		dispatch('setPriceModifications', response.priceModifications);

		if (response.success) {
			commit('SET_PROMOCODE', response.code);
		}

		commit('SET_PENDING', [PENDING_KEY, false]);

		return response;
	},

	async applyPoints({ state, commit, dispatch }, points) {
		const PENDING_KEY = 'points';

		commit('SET_PENDING', [PENDING_KEY, true]);

		const response = await this.$api.post(ENDPOINT_POINTS, { points });
		dispatch('setPriceModifications', response.priceModifications);

		response.success && commit('SET_POINTS', points);

		commit('SET_PENDING', [PENDING_KEY, false]);

		return response;
	},

	async cardAction({ state, commit, dispatch }, { method, data }) {
		commit('SET_ITEM_PENDING', [data.id, true]);

		const response = await this.$api[method](ENDPOINT_CARDS, data);
		const responseResult = dispatch('handleResponse', { response, action: 'cardAction', method, data });
		if (!responseResult) { // Возникла модалка, нажато "Закрыть"
			commit('SET_ITEM_PENDING', [data.id, false]);
			return false;
		}

		window.$nuxt.$broadcastChannel.postMessage('cart');

		commit('SET_ITEM_PENDING', [data.id, false]);
		return true;
	},

	async extraAction({ state, commit, dispatch }, { method, data }) {
		const key = `${data.id}-${data.partnerId}`;
		commit('SET_ITEM_PENDING', [key, true]);

		const response = await this.$api[method](ENDPOINT_EXTRA, data);
		const responseResult = dispatch('handleResponse', { response, action: 'extraAction', method, data });
		if (!responseResult) { // Возникла модалка, нажато "Закрыть"
			commit('SET_ITEM_PENDING', [key, false]);
			return false;
		}

		window.$nuxt.$broadcastChannel.postMessage('cart');

		commit('SET_ITEM_PENDING', [key, false]);
		return true;
	},

	async itemAction({ state, commit, dispatch, getters }, { method, data }) {
		commit('SET_ITEM_PENDING', [data.id, true]);

		const [response] = await Promise.all([
			this.$api[method](ENDPOINT_ITEMS, data),
			sleep(600),
		]);

		let product = method === 'delete'
			? { ...getters.products.find(p => p.id === data.id) }
			: null;

		const responseResult = await dispatch('handleResponse', { response, action: 'itemAction', method, data });
		if (!responseResult) { // Возникла модалка, нажато "Закрыть"
			commit('SET_ITEM_PENDING', [data.id, false]);
			return false;
		}

		if (method === 'post' && response.success) {
			product = { ...getters.products.find(p => p.id === data.id) };
		}

		if (product && Object.keys(product).length) {
			this.$notifications.add({
				image: product.image,
				type: 'cart',
				status: method === 'post' ? 'in' : 'leave',
			});
		}

		window.$nuxt.$broadcastChannel.postMessage('cart');

		commit('SET_ITEM_PENDING', [data.id, false]);
		return true; // Используется при оформлении заказа на странице товара для редиректа
	},

	async handleResponse({ commit, dispatch }, { response, action, method, data }) {
		if (response.items) {
			commit('SET_ITEMS', response.items);
			commit('SET_CRUTCH', response.items);
		}
		if (response.extra) {
			commit('SET_EXTRA', response.extra);
			commit('SET_CRUTCH', response.extra);
		}
		if (response.priceModifications) dispatch('setPriceModifications', response.priceModifications);
		if (response.cards) commit('SET_CARDS', response.cards);
		if (response.settings) commit('SET_SETTINGS', response.settings);

		if (response.message) {
			return dispatch('openNotification', { message: response.message, action, method, data });
		}

		return true;
	},

	async removeExcessCards({ dispatch, getters: { cardsForId } }, { id, quantity }) {
		const cardsCount = cardsForId[id].length;
		const excessCards = cardsForId[id].slice(cardsCount - quantity);
		const cards = excessCards.map(card => dispatch('cardAction', { method: 'delete', data: { id: card.id } }));

		await Promise.all(cards);
	},

	async openNotification({ dispatch }, { message, action, method, data }) {
		const title = message?.title || 'Уведомление';
		const text = message?.text || 'Упс, что-то пошло не так.';

		const actions = [];

		message.needAccept && actions.push(
			{ variant: 'primary', text: 'Согласиться', closeWith: 'accepted' },
			{ variant: 'link', text: 'Закрыть', closeWith: 'default' },
		);

		const result = await window.$nuxt.$modals.open('notification', {
			bind: { title, text, actions },
		});

		if (result === 'accepted') {
			await dispatch(action, { method, data: { ...data, accepted: 1 } });
		}

		return result === 'accepted';
	},
};


export const getters = {
	isPending: (state) => state[PENDING_PROP],
	isItemPending: (state) => Object.entries(state[PENDING_PROP])
		.filter(([key]) => key.startsWith('ITEM_'))
		.reduce((acc, [key, value]) => ({ ...acc, [key.replace('ITEM_', '')]: value }), {}),

	isDeliveriesCombined: (state) => state[SETTINGS_PROP]?.isDeliveriesCombined || false,

	isCheckoutDisabled: (state, { products }) => products.find(p => p.availableQuantity < p.quantity),

	partners: (state) => state[PARTNERS_PROP] || [],
	partnerById: (state, { partners }) => partners.reduce((acc, curr) => ({ ...acc, [curr.id]: curr }), {}),
	partnerExtraProducts: (state, { partners }) => partners.reduce((acc, curr) => {
		return { ...acc, [curr.id]: curr.products };
	}, {}),
	partnerCardDesignCategories: (state, { partners }) => partners.reduce((acc, curr) => {
		return { ...acc, [curr.id]: curr.cardDesignCategories || [] };
	}, {}),
	cardImagesByDesign: (state, { partners }) => partners.reduce((acc, partner) => {
		if (!partner.cardDesignCategories?.length) return acc;

		const cardsByDesign = partner.cardDesignCategories
			.reduce((cardImages, { id, items }) => {
				const imagesInsideCategory = items.reduce((imagesInside, curr) => ({
					...imagesInside, [curr.id]: curr.image,
				}), {});
				return { ...cardImages, ...imagesInsideCategory };
			}, {});

		return { ...acc, [partner.id]: cardsByDesign };
	}, {}),

	products: (state) => state[ITEMS_PROP] || [],
	extra: (state) => state[EXTRA_PROP] || [],
	cards: (state) => state[CARDS_PROP] || [],

	count: (state, { products, extra }) => products.length + extra.length,

	deliveries: (state, { partners, products, extra, cards }) => partners.reduce((acc, { id }) => {
		const items = products.filter(p => p.partnerId === id);
		if (!items.length) return acc;

		return [...acc, {
			partnerId: id,
			[ITEMS_PROP]: items,
			[EXTRA_PROP]: extra.filter(p => p.partnerId === id),
			[CARDS_PROP]: cards.filter(p => p.partnerId === id),
		}];
	}, []),
	deliveriesCount: (state, { deliveries }) => deliveries.length,

	isCartFilled: (state, { deliveriesCount }) => deliveriesCount >= 1,

	isInCart: (state, { products, extra }) => {
		const productsInCart = products.reduce((acc, curr) => { acc[curr.id] = true; return acc; }, {});
		const extraProductsInCart = extra.reduce((acc, curr) => { acc[`${curr.id}-${curr.partnerId}`] = true; return acc; }, {});

		return { ...productsInCart, ...extraProductsInCart };
	},

	cardsForId: (state, { cards }) => cards.reduce((acc, curr) => {
		if (acc[curr.parentId]) {
			acc[curr.parentId].push(curr);
			return acc;
		}
		return { ...acc, [curr.parentId]: [curr] };
	}, {}),

	isCardDesignEnabled: (state, { cards, partners, products }) => cards.reduce((acc, card) => {
		const product = products.find(p => p.id === card.parentId);
		const partner = partners.find(p => p.id === product.partnerId);
		return { ...acc, [card.id]: partner.isCardDesignEnabled };
	}, {}),

	cost: (state, { products, extra }) => [products, extra].reduce((acc, curr) => {
		return acc + curr.reduce((a, c) => a + c.quantity * c.price, 0);
	}, 0),

	totalCost: (state, { cost, discount, points, deliveryCost }) => cost - discount - points + (deliveryCost || 0),
	deliveryCost: (state) => state[DELIVERY_PROP],

	promocode: (state) => state[PROMOCODE_PROP],
	discount: (state) => state[DISCOUNT_PROP],
	points: (state) => state[POINTS_PROP],

	// Список элементов (букетов и доп. товаров) в корзине, используется на странице `page-checkout`
	// Бесплатные открытки намеренно игнорируются в списках:
	// https://bitrix24.realweb.ru/company/personal/user/103/tasks/task/view/22599/ (доработка 44)
	formattedItems: (state, { products, extra, cardsForId }) => {
		const addedPartnerIds = [];
		const extraProducts = products.reduce((acc, product) => {
			if (addedPartnerIds.includes(product.partnerId)) return acc;
			const productExtra = extra.filter(i => i.partnerId === product.partnerId)
				.reduce((acc, curr) => [...acc, curr], []);
			addedPartnerIds.push(product.partnerId);
			return [...acc, ...productExtra];
		}, []);

		return [...products, ...extraProducts];
	},

	vuexCrutchToUpdate: (state) => state[CRUTCH_PROP],
};
