
	import _prop from 'vue-types';
	import SafeNuxtLink from '~components/safe-nuxt-link/safe-nuxt-link.vue';

	export default {
		name: 'footer-nav-li',
		block: 'footer-nav',
		components: { SafeNuxtLink },
		props: {
			code: _prop.string,
			link: _prop.string,
			icon: _prop.string,
			label: _prop.string,
			depth: _prop.integer,
			items: _prop.array,
		},
		data: () => ({
			isExpanded: false,
		}),
		computed: {
			cComponent() {
				if (!this.link) return 'span';
				return this.link === this.$route.path ? 'span' : 'a';
			},

			cModifiers() {
				return {
					hasLink: !!this.link,
					depth: this.depth ?? 1,
					parent: !!this.items,
					sub: this.depth > 1,
					heading: this.cComponent === 'span' && !this.link,
					big: this.depth === 1,
				};
			},

			cAttrs() {
				return this.cComponent === 'a'
					? { href: this.link }
					: {};
			},
		},
		methods: {},
		created() {
			this.isExpanded = this.$device.isDesktopOrTablet;
		},
		mounted() {
			this.isExpanded = this.$device.isDesktopOrTablet;
		},
	};
