
	/* eslint-disable vue/match-component-file-name */
	import _prop from 'vue-types';

	import Page404 from '~pages/page-404/page-404.vue';

	export default {
		name: 'page-error',
		components: { Page404 },
		layout: 'static-header',
		props: {
			error: _prop.object,
		},
		head() {
			return this.$nuxtI18nHead({ addSeoAttributes: false });
		},
	};
