import Vue from 'vue';
import { omit } from '@morev/utils';
import { useGlobalStore } from '~stores/index.js';

export const state = () => ({
	isLoading: false,
	data: {},
	date: null,
	stagedDate: null,
});

export const mutations = {
	SET_DATA: (state, data) => Vue.set(state, 'data', data),

	SET_DATE: (state, date) => state.date = date,
	SET_STAGED_DATE: (state, date) => state.stagedDate = date,
	RESET_STAGED_DATE: (state) => state.stagedDate = null,

	SET_LOADING: (state, value) => state.isLoading = value,
};

export const actions = {
	async load({ commit }, { id }) {
		// Get product data
		const data = await this.$api.get('/products-item', { id });
		// Get active date
		const activeDate = data.dates?.find(d => d.active === true)?.date || null;
		// Remove active flag from dates
		data.dates.map(i => omit(i, 'active'));

		commit('SET_DATA', data);
		commit('SET_DATE', activeDate);
	},

	async refetch({ dispatch, commit, state }) {
		commit('SET_LOADING', true);
		await dispatch('load', { id: state.data.id });
		commit('SET_LOADING', false);
	},

	async applyStaged({ state, commit }) {
		if (!state.stagedDate) return;

		const globalStore = useGlobalStore();

		commit('SET_DATE', state.stagedDate);
		commit('RESET_STAGED_DATE');
		await this.$api.post('/dates-set/', { date: state.date });
		window.$nuxt.$broadcastChannel.postMessage('date');

		globalStore.setDate(state.date);
	},
};

export const getters = {
	product: (state) => state.data,
	dates: (state, { product, isDateActive }) => product.dates.map(i => ({ ...i, active: isDateActive(i.date) })),
	activeDateObject: (state, { dates }) => dates.find(i => i.active),
	selectedDate: (state) => (state.stagedDate || state.date),
	isDateActive: (state, { selectedDate }) => (date) => selectedDate === date,
	isActiveToday: (state, { selectedDate }) => state.data.dates.find(d => d.date === selectedDate)?.semantic === 'today',
};
