
	import _prop from 'vue-types';

	export default {
		name: 'base-section',
		props: {
			tag: _prop.string.def('section'),
		},
		data: () => ({}),
		computed: {},
		methods: {},
	};
