export const state = () => ({
	isDeliveryLoading: false,
	isCheckoutLoading: false,
});

export const mutations = {
	SET_CHECKOUT_LOADING: (state, isLoading) => (state.isCheckoutLoading = isLoading),
	SET_DELIVERY_LOADING: (state, isLoading) => (state.isDeliveryLoading = isLoading),
};

export const actions = {};
