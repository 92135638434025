import { render, staticRenderFns } from "./section-404.vue?vue&type=template&id=66037f0e"
import script from "./section-404.vue?vue&type=script&lang=js"
export * from "./section-404.vue?vue&type=script&lang=js"
import style0 from "./section-404.scss?vue&type=style&index=0&prod&lang=scss&external"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./section-404.i18n.yaml?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fsrv%2Fwww%2Fuflor.ru%2Fhtdocs%2Frelease%2Ffrontend%2Fviews%2Fcomponents%2F__pages%2Fpage-404%2F_section-404%2Fsection-404.vue&lang=yaml&external"
if (typeof block0 === 'function') block0(component)

export default component.exports