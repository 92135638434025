
	import _prop from 'vue-types';
	import { mapGetters } from 'vuex';
	import CatalogNavPrimary from '~components/catalog-nav/_catalog-nav-primary/catalog-nav-primary.vue';
	import SafeNuxtLink from '~components/safe-nuxt-link/safe-nuxt-link.vue';
	import UserData from '~components/user-data/user-data.vue';
	import CatalogNavSecondary from '~components/catalog-nav/_catalog-nav-secondary/catalog-nav-secondary.vue';
	import CatalogNavTertiary from '~components/catalog-nav/_catalog-nav-tertiary/catalog-nav-tertiary.vue';

	export default {
		name: 'header-nav-mobile',
		components: { UserData, SafeNuxtLink, CatalogNavPrimary, CatalogNavSecondary, CatalogNavTertiary },
		props: {
			navItems: _prop.array,
			navShort: _prop.array,
			value: _prop.bool.def(false),
			catalogIndex: _prop.number.def(-1),
		},
		emits: ['root-click'],
		data: () => ({}),
		computed: {
			...mapGetters('user', ['isLogged', 'isInitialized']),
		},
		watch: {
			value(isShown) {
				const method = isShown ? 'add' : 'remove';
				if (this.catalogIndex !== null && this.catalogIndex !== -1) {
					setTimeout(() => this.$el.classList[method]('instant'), 20);
					this.activeRootIndex = this.activeCatalogIndex;
				} else {
					setTimeout(() => this.$el.classList[method]('is-mounted'), 20);
				}
			},
		},
		methods: {},
	};
