import { defineStore } from 'pinia';
import dayjs from 'dayjs';
import { getMonthName } from '~/scripts/helpers';

const GET_PARAM_DATE = 'date';

export const useGlobalStore = defineStore('global', {
	state: () => ({
		cityId: null,
		date: {
			_data: [],
			current: null,
			staged: null,
		},
	}),

	actions: {
		async init() {
			const date = this.$nuxt.route.query[GET_PARAM_DATE] ?? null;
			const response = await this.$nuxt.$api.get('/globals', { date });

			this.cityId = response?.cityId ?? null;
			const dates = response?.dates ?? [];
			this.date._data = dates;

			const activeDateObject = dates.find(i => i.active) || dates[0];
			this.date.current = activeDateObject.date;
		},

		setDate(dateValue) {
			this.date.current = dateValue;
		},

		stageDate(dateValue) {
			this.date.staged = dateValue;
		},

		resetStagedDate() {
			this.date.staged = null;
		},

		applyDate(applyGetParams = false) {
			this.date.staged && (this.date.current = this.date.staged);
			this.resetStagedDate();

			if (applyGetParams) {
				this.$nuxt.app.router.replace({
					query: {
						...this.$nuxt.route.query,
						[GET_PARAM_DATE]: this.activeDate,
					},
				}).catch(e => {});
			}

			this.$nuxt.$api.post('/dates-set/', {
				date: this.selectedDate,
			}).then(() => window.$nuxt.$broadcastChannel.postMessage('date'));
		},
	},

	getters: {
		dates: (state) => state.date._data.reduce((acc, { date, semantic, active }) => {
			acc.push({ date, semantic, active });
			return acc;
		}, []),

		selectedDate: (state) => state.date.current,

		activeDate: (state) => state.date.staged ?? state.date.current,

		humanDate: (state) => {
			const dateObj = dayjs(state.activeDate).toDate();
			return `${dateObj.getDate()} ${getMonthName(dateObj.getMonth())}`;
		},
	},
});
