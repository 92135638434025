/* eslint-disable no-console */
import { formatSlashes, queryStringify } from '@morev/utils';
import Vue from 'vue';

const COLLECT_FLAG = '#__collect_requests__';
const REQUESTS_COLLECTION = '#__requests__';

const isServer = process.server || typeof window === 'undefined';

const getStageByType = (type) => {
	switch (type) {
		case 'SERVER': return 'Серверный рендеринг';
		case 'PAGE': return 'Действие на странице';
		case 'ROUTE': return 'Переход на страницу';
		default: return 'UNKNOWN';
	}
};

const getQueryParams = (params) => {
	const urlSearchParams = queryStringify(params || {}) || null;
	return urlSearchParams ? `?${urlSearchParams}`.replace(/&$/, '') : '';
};

export default class ApiPrinter {
	#ctx = null;

	[COLLECT_FLAG] = true;

	[REQUESTS_COLLECTION] = [];

	#openApiPrefix = 'https://live190.ru/open-api/';

	#apiUri = '/api-v2/';

	constructor(ctx) {
		this.#ctx = ctx;

		this[REQUESTS_COLLECTION] = Vue.observable([]);
		this[COLLECT_FLAG] = true;

		process.server && (ctx.ssrContext.nuxt[REQUESTS_COLLECTION] = this[REQUESTS_COLLECTION]);
		!process.server && this.printRequests({ type: 'SERVER' });
	}

	startRequestsCollection() {
		this[COLLECT_FLAG] = true;
	}

	endRequestsCollection() {
		this[COLLECT_FLAG] = false;
		if (process.server) return;

		this.printRequests({ type: 'ROUTE' });
		this[REQUESTS_COLLECTION] = [];
	}

	printRoute(route, requests) {
		const methods = [...new Set(requests.map(r => r.method))];
		const method = methods.join(' | ');

		route = route.replaceAll(/^\/|\/$/g, '');
		const isLocal = route.startsWith('local');
		const cleanRoute = route.replace(/^local\//, '');

		const requestsCount = requests.length > 1 ? `(${requests.length}) ` : ``;

		const decoratorNotices = [];
		if (requests.some(r => r.wasRequestTransformed)) decoratorNotices.push('Запрос был изменён');
		if (requests.some(r => r.wasResponseTransformed)) decoratorNotices.push('Ответ был изменён');
		const decoratorNotice = decoratorNotices.length ? `| ${decoratorNotices.join(' | ')}` : '';

		const groupName = `[${method}] /${route}/ ${requestsCount}${decoratorNotice}`;
		console.groupCollapsed(groupName);
		console.log(`Спецификация:    ${this.#openApiPrefix}#operation/${method.toLowerCase()}-${cleanRoute}`);

		const localUrlName = isLocal ? `Локальный URL:   ` : `Реальный URL:    `;

		requests.forEach(({ params, response, wasRequestTransformed, wasResponseTransformed }) => {
			(requests.length > 1) && console.log(`------------`);
			const prefix = isLocal ? `local-api` : formatSlashes(this.#apiUri, { start: false, end: false });
			const queryParams = getQueryParams(params);
			const localUrl = decodeURIComponent(`${window.location.origin}/${prefix}/${cleanRoute}/${queryParams}`);
			console.log(`${localUrlName}${localUrl}`);
			Object.keys(params || {}).length && console.log(`Параметры:      `, params);
			console.log(`Ответ сервера:  `, response);

			const routeTansformerNotices = [];
			wasRequestTransformed && routeTansformerNotices.push('Запрос был изменён');
			wasResponseTransformed && routeTansformerNotices.push('Ответ был изменён');
			routeTansformerNotices.length && console.log(routeTansformerNotices.join(' | '));
		});
		console.groupEnd();
	}

	printRequests({ type }) {
		if (isServer) return;

		const { path } = this.#ctx.route;
		const requests = type === 'SERVER'
			? (window.__NUXT__[REQUESTS_COLLECTION] || []).map(r => JSON.parse(r))
			: (this[REQUESTS_COLLECTION] || []).map(r => JSON.parse(r));
		if (!requests.length) return;

		const stage = getStageByType(type);
		const isPageRequest = type === 'PAGE';

		const parts = [
			{
				groupName: 'Реальные запросы ({{COUNT}}) ',
				requests: requests.filter(r => !r.local),
			},
			{
				groupName: 'Локальные запросы ({{COUNT}}) ',
				requests: requests.filter(r => r.local)
					.map(r => ({
						...r,
						route: r.route.startsWith('local') ? r.route : `local/${r.route}`,
					})),
			},
		].filter(part => part.requests.length);

		const isDifferentTargets = parts.length > 1;

		const hasHeading = (isPageRequest && isDifferentTargets) || !isPageRequest;

		console.group(`[API] [${stage}] ${window.location.origin}${path}`);

		parts.forEach(part => {
			const groupedByRoute = part.requests
				.reduce((acc, {
					method, route, params, response, wasRequestTransformed, wasResponseTransformed,
				}) => {
					acc[route] = acc[route] || []; // @TODO: Nullish babel plugin
					acc[route].push({
						method, params, response, wasRequestTransformed, wasResponseTransformed,
					});
					return acc;
				}, {});
			hasHeading && console.groupCollapsed(part.groupName.replace('{{COUNT}}', Object.keys(groupedByRoute).length));
			Object.entries(groupedByRoute).forEach(([route, queries]) => {
				this.printRoute(route, queries, type === 'PAGE');
			});

			hasHeading && console.groupEnd();
		});

		console.groupEnd();
	}

	processRequest({
		method, url, data, requests, isComposite, response, requestDecorators = [], responseDecorators = [],
	}) {
		if (isComposite) {
			this[REQUESTS_COLLECTION].push(
				...requests.map(r => JSON.stringify({
					method: 'GET',
					...r,
					response: r.local ? response?.[r.key] || null : response?.[r.key]?.data || null,
					wasRequestTransformed: requestDecorators.includes(r.key),
					wasResponseTransformed: responseDecorators.includes(r.key),
				})),
			);
		} else {
			const cleanApiUri = formatSlashes(this.#apiUri, { start: false, end: false });
			const apiRe = new RegExp(`^/?${cleanApiUri}/`);

			this[REQUESTS_COLLECTION].push(
				JSON.stringify({
					method: method.toUpperCase(),
					route: url.replace(apiRe, ''),
					params: data,
					local: url.startsWith('local/'),
					response,
					wasRequestTransformed: requestDecorators.includes(true),
					wasResponseTransformed: responseDecorators.includes(true),
				}),
			);
		}

		if (!process.server && !this[COLLECT_FLAG]) {
			// To group multiple sequentall requests in one entry
			setTimeout(() => {
				this.printRequests({ type: 'PAGE' });
				this[REQUESTS_COLLECTION] = [];
			}, 200);
		}
	}
}
