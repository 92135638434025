
	import _prop from 'vue-types';
	import AppTag from '~components/_app/app-tag/app-tag.vue';

	export default {
		name: 'app-tags',
		components: { AppTag },
		props: {
			items: _prop.array,
			useFlex: _prop.bool.def(false),
		},
		emits: ['click'],
		data: () => ({}),
		computed: {},
		methods: {},
	};
