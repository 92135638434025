/**
 * Set usefull classes to root element (html).
 *
 * Table of contents:
 *
 * .mobile            Applies when using a mobile browser.
 * .ie                Applies when using a legacy IE (10 or 11).
 * Edge works good and doesn't required any special fixes.
 * .ff                Applies when using any version of Firefox browser.
 * .osx               Applies when using any Apple device (MacBook, iMac, iPhone, iPad, iPod (O_o)).
 * .dom-loaded        Applies when window.DOMContentLoaded event is fired.
 * .loaded            Applies when window.load event is fired.
 * .no-webp           Applies if browser doesn't support WebP format.
 * .(no)-touch        Applies when touch events are supported (or not supported with no- prefix).
 */
import { isMobile, isOSX, isInternetExplorer, isFirefox } from '@morev/utils';

// Root ref
const html = document.documentElement;

// Check touch devices
if ('ontouchstart' in window || navigator.msMaxTouchPoints) {
	html.classList.add('touch');
} else {
	html.classList.add('no-touch');
}

isMobile() && html.classList.add('mobile');
isOSX() && html.classList.add('osx');
isInternetExplorer() && html.classList.add('ie');
isFirefox() && html.classList.add('ff');

// Check WebP support
const img = new Image();
img.src = 'data:image/webp;base64,UklGRi4AAABXRUJQVlA4TCEAAAAvAUAAEB8wAiMwAgSSNtse/cXjxyCCmrYNWPwmHRH9jwMA';
// eslint-disable-next-line no-multi-assign
img.onload = img.onerror = () => (img.height !== 2) && html.classList.add('no-webp');

// Load catcher
window.addEventListener('DOMContentLoaded', () => html.classList.add('dom-loaded'));
window.addEventListener('load', () => html.classList.add('loaded'));
