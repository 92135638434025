
	import _prop from 'vue-types';

	export default {
		name: 'common-social-links',
		props: {
			type: _prop.oneOf(['default', 'mobile', 'footer', 'header']).def('default'),
			items: _prop.array,
			mail: _prop.string,
			gap: _prop.string,
		},
		emits: ['click'],
		data: () => ({}),
		computed: {
			shouldRender() {
				return this.computedItems.length || this.mail;
			},

			computedItems() {
				return this.items.filter(i => i.network && i.link);
			},
		},
		methods: {
			onClick($event, network) {
				if (!this.$listeners.click) return;

				this.$emit('click', network);
				$event.preventDefault();
			},
		},
	};
