import Vue from 'vue';

const defaultState = {
	isActive: false,
	message: {
		title: '',
		text: '',
		actions: [],
	},
};

export const state = () => defaultState;

export const mutations = {
	RESET: (state) => Object.entries(defaultState).forEach(([key, value]) => Vue.set(state, key, value)),

	// SET_OBJECT: (state) =>
};

export const getters = {
	notification: (state) => JSON.stringify(state.message),
	isActive: (state) => state.isActive,
};
