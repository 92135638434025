
	import _prop from 'vue-types';
	import BaseButton from '~components/_base/base-button/base-button.vue';

	const COOKIE_KEY = 'cookie-notification-closed';

	export default {
		name: 'the-cookie-notification',
		components: { BaseButton },
		props: {},
		data: () => ({
			isActive: false,
		}),
		computed: {},
		methods: {
			hideNotification() {
				this.isActive = false;
				this.$cookies.set(COOKIE_KEY, 1, { maxAge: 60 * 60 * 24 * 365, path: '/' }); // 1 year
			},
		},
		mounted() {
			!this.$cookies.get(COOKIE_KEY) && (this.isActive = true);
		},
	};
