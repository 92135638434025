
	import _prop from 'vue-types';

	export default {
		name: 'header-nav-li',
		inheritAttrs: false,
		block: 'header-nav',
		components: {},
		props: {
			code: _prop.string,
			link: _prop.string,
			label: _prop.string,
		},
		computed: {
			cComponent() {
				return this.link === this.$route.path ? 'span' : 'a';
			},

			cBind() {
				return this.cComponent === 'a'
					? { href: this.link }
					: {};
			},
		},
		methods: {},
	};
