
	import { nanoid } from 'nanoid/non-secure';

	const has = (ctx, prop) => Object.prototype.hasOwnProperty.call(ctx, prop);
	const setId = (o, id) => {
		if (!has(o, '__id') || id) {
			return Object.defineProperty(o, '__id', Object.assign(Object.create(null), {
				value: id || nanoid(9),
			}));
		}
		return o;
	};

	export default {
		name: 'formulate-input-group',
		components: {},
		props: {
			context: {type: Object, required: true},
			groupClasses: {type: Object, default: () => ({})},
		},
		computed: {
			options() {
				return this.context.options || [];
			},
			subType() {
				return (this.context.type === 'group') ? 'grouping' : 'inputs';
			},
			optionsWithContext() {
				const {
					// The following are a list of items to pull out of the context object
					attributes: {id, ...groupApplicableAttributes},
					blurHandler,
					classification,
					component,
					getValidationErrors,
					hasLabel,
					hasValidationErrors,
					isSubField,
					isValid,
					labelPosition,
					options,
					performValidation,
					setErrors,
					slotComponents,
					slotProps,
					validationErrors,
					visibleValidationErrors,
					classes,
					showValidationErrors,
					rootEmit,
					help,
					pseudoProps,
					rules,
					model,
					...context
				} = this.context;

				Object.assign(context, this.groupClasses);

				return this.options.map(option => this.groupItemContext(
					context,
					option,
					groupApplicableAttributes,
				));
			},
			totalItems() {
				return Array.isArray(this.context.model) && this.context.model.length > this.context.minimum
					? this.context.model.length
					: this.context.minimum || 1;
			},
			canAddMore() {
				return (this.context.repeatable && this.totalItems < this.context.limit);
			},
			labelledBy() {
				return this.context.label && `${this.context.id}_label`;
			},
		},
		methods: {
			addItem() {
				if (Array.isArray(this.context.model)) {
					const minDiff = (this.context.minimum - this.context.model.length) + 1;
					const toAdd = Math.max(minDiff, 1);
					for (let i = 0; i < toAdd; i++) {
						this.context.model.push(setId({}));
					}
				} else {
					this.context.model = (new Array(this.totalItems + 1)).fill('').map(() => setId({}));
				}
				this.context.rootEmit('repeatableAdded', this.context.model);
			},
			groupItemContext(context, option, groupAttributes) {
				const ctx = Object.assign({},
					context,
					option,
					groupAttributes,
					{isGrouped: true},
					!context.hasGivenName ? {name: true} : {},
				);
				return ctx;
			},
		},
	};
