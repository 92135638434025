/**
 * Handles textareas height. Stretches them to fit user text.
 * Runs if textarea has a v-textarea-auto-size directive.
 * Can contain object as directive value with param `max`,
 * which should contain integer value in px like `50`.
 *
 * Example of usage:
 * <textarea v-textarea-auto-size></textarea> // no limit
 * <textarea v-textarea-auto-size="{max: 50}"></textarea> // limit max height
 */

import Vue from 'vue';

const stretchCarry = (params) => {
	params = { max: null, ...params };

	return function ({ target }) {
		if (target.tagName.toLowerCase() !== 'textarea') return;

		const clone = target.cloneNode(true);
		clone.style.position = 'absolute';
		clone.style.left = '-9999px';
		clone.style.height = 'auto';
		clone.style.width = `${target.scrollWidth}px`;
		target.parentNode.append(clone);

		const offset = target.offsetHeight - target.clientHeight;
		const height = params.max
			? Math.min(clone.scrollHeight + offset, params.max)
			: clone.scrollHeight + offset;
		target.style.height = `${height}px`;

		clone.remove();
	};
};

Vue.directive('textarea-auto-size', {
	bind(el, { value, modifiers }, vnode) {
		if (value === false) return;
		el.addEventListener('input', stretchCarry(value));
	},

	unbind(el, { value, modifiers }, vnode) {
		el.removeEventListener('input', stretchCarry);
	},
});
