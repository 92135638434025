import { render, staticRenderFns } from "./catalog-nav-root.vue?vue&type=template&id=4720132a"
import script from "./catalog-nav-root.vue?vue&type=script&lang=js"
export * from "./catalog-nav-root.vue?vue&type=script&lang=js"
import style0 from "./catalog-nav-root.scss?vue&type=style&index=0&prod&lang=scss&external"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TransitionSlide: require('/srv/www/uflor.ru/htdocs/node_modules/.vue-transitions/TransitionSlide.vue').default})
