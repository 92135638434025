
	import _prop from 'vue-types';
	import SafeNuxtLink from '~components/safe-nuxt-link/safe-nuxt-link.vue';

	export default {
		name: 'app-tag',
		inheritAttrs: false,
		components: { SafeNuxtLink },
		props: {
			index: _prop.integer,
			link: _prop.string.def(''),
			text: _prop.string.def(''),
			active: _prop.bool.def(false),
		},
		emits: ['click'],
		data: () => ({}),
		computed: {},
		methods: {},
	};
