
	import TheHeader from '~components/_the/the-header/the-header.vue';
	import TheHero from '~components/_the/the-hero/the-hero.vue';
	import TheFooter from '~components/_the/the-footer/the-footer.vue';

	import TheCookieNotification from '~components/_the/the-cookie-notification/the-cookie-notification.vue';
	import TheInteractions from '~components/_the/the-interactions/the-interactions.vue';
	import TheNotifications from '~components/_the/the-notifications/the-notifications.vue';
	import TheVersionChecker from '~components/_the/the-version-checker/the-version-checker.vue';
	import TheInfoMessage from '~components/_the/the-info-message/the-info-message.vue';
	import { mapState } from 'vuex';
	import { mapStores } from 'pinia';
	import { useEntityStore } from '~stores/index.js';
	import LazyHydrate from 'vue-lazy-hydration';

	export default {
		// eslint-disable-next-line vue/match-component-file-name
		name: 'layout-default',
		components: {
			LazyHydrate,
			//
			TheHeader,
			TheFooter,
			TheHero,
			TheCookieNotification,
			TheInteractions,
			TheNotifications,
			TheVersionChecker,
			TheInfoMessage,
		},
		head() {
			return this.$nuxtI18nHead({ addSeoAttributes: false });
		},
		computed: {
			...mapState('layout', ['infoMessage']),
			...mapStores(useEntityStore),

			cHeroVariant() {
				return this.entityStore.current.pageInfo?.hero?.variant ?? 'small';
			},
		},
		watch: {},
	};
