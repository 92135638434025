import { arrayUnique } from '@morev/utils';

export const COOKIE_KEY = 'seen-notification-ids';

export const getInfoMessageIds = (nuxtCookies) => {
	return (nuxtCookies.get(COOKIE_KEY)?.toString() || '')
		.split(',').map(Number).filter(Boolean);
};

export const setInfoMessageIds = (nuxtCookies, ids) => {
	const newIds = arrayUnique([...getInfoMessageIds(nuxtCookies), ...ids]);
	nuxtCookies.set(COOKIE_KEY, newIds.join(','), { path: '/', maxAge: 60 * 60 * 24 * 365 }); // 1 year
};
