import { BroadcastChannel } from 'broadcast-channel';

class CustomBroadcast {
	constructor() {
		this.registeredListeners = [];
		this.listeners = {};
		this.channel = new BroadcastChannel('karamelka', {
			webWorkerSupport: false,
		});

		this.channel.onmessage = ({ type, data }) => {
			if (!this.listeners[type]) return;
			this.listeners[type].forEach(cb => cb(data));
		};
	}

	registerListeners(key, callback) {
		if (this.registeredListeners.includes(key)) return;
		this.registeredListeners.push(key);
		callback();
	}

	postMessage(type, data) {
		this.channel.postMessage({ type, data }, '*');
	}

	onMessage(type, callback) {
		this.listeners[type] ||= [];
		const typeCallbacks = this.listeners[type];
		if (typeCallbacks.includes(callback)) return;
		typeCallbacks.push(callback);
	}
}

export default (ctx, inject) => {
	// Если называется просто `broadcast` - роняет `Vue DevTools` ¯\_(ツ)_/¯
	inject('broadcastChannel', new CustomBroadcast());
};
