
	import dayjs from 'dayjs';
	import _prop from 'vue-types';

	import ExternalScrollbar from '~components/_external/external-scrollbar/external-scrollbar.vue';
	import BaseButton from '~components/_base/base-button/base-button.vue';
	import { getMonthName } from '~/scripts/helpers';

	export default {
		name: 'date-time-picker-inner',
		components: { ExternalScrollbar, BaseButton },
		props: {
			dates: _prop.array,
			times: _prop.array,
			skipTitles: _prop.bool.def(false),
		},
		emits: [
			'apply',
			'date-click',
			'time-click',
		],
		data: () => ({}),
		computed: {
			isOnlyDate() {
				return this.times.length === 0;
			},

			cDates() {
				return this.dates.map(({ date, semantic, active }) => {
					const dateObj = dayjs(date).toDate();
					const day = dateObj.getDate().toString().padStart(2, '0');
					const month = getMonthName(dateObj.getMonth());
					semantic &&= this.$t(semantic);

					return {
						date, day, month, semantic, active,
					};
				});
			},
		},
		methods: {},
	};
