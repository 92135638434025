import Vue from 'vue'
import Vuex from 'vuex'
import Meta from 'vue-meta'
import ClientOnly from 'vue-client-only'
import NoSsr from 'vue-no-ssr'
import { createRouter } from './router.js'
import NuxtChild from './components/nuxt-child.js'
import NuxtError from '../frontend/views/layouts/error.vue'
import Nuxt from './components/nuxt.js'
import App from './App.js'
import { setContext, getLocation, getRouteData, normalizeError } from './utils'
import { createStore } from './store.js'

/* Plugins */

import nuxt_plugin_plugin_cd5d8334 from 'nuxt_plugin_plugin_cd5d8334' // Source: ./components/plugin.js (mode: 'all')
import nuxt_plugin_plugin_36ea5a64 from 'nuxt_plugin_plugin_36ea5a64' // Source: ./composition-api/plugin.mjs (mode: 'all')
import nuxt_plugin_nuxtsvgsprite_37017acc from 'nuxt_plugin_nuxtsvgsprite_37017acc' // Source: ./nuxt-svg-sprite.js (mode: 'all')
import nuxt_plugin_pluginutils_0e3ce33f from 'nuxt_plugin_pluginutils_0e3ce33f' // Source: ./nuxt-i18n/plugin.utils.js (mode: 'all')
import nuxt_plugin_pluginrouting_28919cca from 'nuxt_plugin_pluginrouting_28919cca' // Source: ./nuxt-i18n/plugin.routing.js (mode: 'all')
import nuxt_plugin_pluginmain_38d792e5 from 'nuxt_plugin_pluginmain_38d792e5' // Source: ./nuxt-i18n/plugin.main.js (mode: 'all')
import nuxt_plugin_vuescreen_7a9c6aae from 'nuxt_plugin_vuescreen_7a9c6aae' // Source: ./vue-screen.js (mode: 'all')
import nuxt_plugin_libplugin19577b34_1e48d70a from 'nuxt_plugin_libplugin19577b34_1e48d70a' // Source: ./lib.plugin.19577b34.js (mode: 'all')
import nuxt_plugin_axios_de7d5b08 from 'nuxt_plugin_axios_de7d5b08' // Source: ./axios.js (mode: 'all')
import nuxt_plugin_api_30d8db9c from 'nuxt_plugin_api_30d8db9c' // Source: ./frontend-api/api.js (mode: 'all')
import nuxt_plugin_cookieuniversalnuxt_41c30bb8 from 'nuxt_plugin_cookieuniversalnuxt_41c30bb8' // Source: ./cookie-universal-nuxt.js (mode: 'all')
import nuxt_plugin_pluginstatic_38ae93e8 from 'nuxt_plugin_pluginstatic_38ae93e8' // Source: ../../node_modules/@runway-frontend/nuxt-scroll/dist/runtime/plugin-static.mjs (mode: 'all')
import nuxt_plugin_smoothscrollpluginclient_0fba1bad from 'nuxt_plugin_smoothscrollpluginclient_0fba1bad' // Source: ./smooth-scroll-plugin.client.js (mode: 'client')
import nuxt_plugin_pluginserver_3419fa40 from 'nuxt_plugin_pluginserver_3419fa40' // Source: ./color-mode/plugin.server.js (mode: 'server')
import nuxt_plugin_pluginclient_633eee90 from 'nuxt_plugin_pluginclient_633eee90' // Source: ./color-mode/plugin.client.js (mode: 'client')
import nuxt_plugin_vbemtransformer_9484f534 from 'nuxt_plugin_vbemtransformer_9484f534' // Source: ../../node_modules/.v-bem-transformer/v-bem-transformer.js (mode: 'all')
import nuxt_plugin_distplugin767a6c2c_3f46251d from 'nuxt_plugin_distplugin767a6c2c_3f46251d' // Source: ./dist.plugin.767a6c2c.mjs (mode: 'all')
import nuxt_plugin_router_3bb588b3 from 'nuxt_plugin_router_3bb588b3' // Source: ./router.js (mode: 'all')
import nuxt_plugin_rootclassesclient_88396562 from 'nuxt_plugin_rootclassesclient_88396562' // Source: ../frontend/plugins/root-classes.client.js (mode: 'client')
import nuxt_plugin_reactiverefs_cf95474e from 'nuxt_plugin_reactiverefs_cf95474e' // Source: ../frontend/plugins/reactive-refs.js (mode: 'all')
import nuxt_plugin_mittclient_46226e20 from 'nuxt_plugin_mittclient_46226e20' // Source: ../frontend/plugins/mitt.client.js (mode: 'client')
import nuxt_plugin_reactiveprovideinject_5c9b8e88 from 'nuxt_plugin_reactiveprovideinject_5c9b8e88' // Source: ../frontend/plugins/reactive-provide-inject.js (mode: 'all')
import nuxt_plugin_yametrika_ccebf046 from 'nuxt_plugin_yametrika_ccebf046' // Source: ../frontend/plugins/ya-metrika.js (mode: 'all')
import nuxt_plugin_modals_1d9973ca from 'nuxt_plugin_modals_1d9973ca' // Source: ../frontend/plugins/modals.js (mode: 'all')
import nuxt_plugin_vueformulate_6af646c6 from 'nuxt_plugin_vueformulate_6af646c6' // Source: ../frontend/plugins/vue-formulate.js (mode: 'all')
import nuxt_plugin_notifications_15a88b85 from 'nuxt_plugin_notifications_15a88b85' // Source: ../frontend/plugins/notifications.js (mode: 'all')
import nuxt_plugin_viewportclient_dea6660c from 'nuxt_plugin_viewportclient_dea6660c' // Source: ../frontend/plugins/viewport.client.js (mode: 'client')
import nuxt_plugin_broadcastclient_5e5c0229 from 'nuxt_plugin_broadcastclient_5e5c0229' // Source: ../frontend/plugins/broadcast.client.js (mode: 'client')
import nuxt_plugin_whatinputclient_a056fe80 from 'nuxt_plugin_whatinputclient_a056fe80' // Source: ../frontend/plugins/a11y/what-input.client.js (mode: 'client')
import nuxt_plugin_storesfastaccess_219f697d from 'nuxt_plugin_storesfastaccess_219f697d' // Source: ../frontend/plugins/vue-mixins/stores-fast-access.js (mode: 'all')
import nuxt_plugin_resizeapi_1f2874f6 from 'nuxt_plugin_resizeapi_1f2874f6' // Source: ../frontend/plugins/vue-mixins/resize-api.js (mode: 'all')
import nuxt_plugin_vuebarclient_f80be602 from 'nuxt_plugin_vuebarclient_f80be602' // Source: ../frontend/plugins/vue-directives/vuebar/vuebar.client.js (mode: 'client')
import nuxt_plugin_clickoutsideclient_75a81c60 from 'nuxt_plugin_clickoutsideclient_75a81c60' // Source: ../frontend/plugins/vue-directives/click-outside.client.js (mode: 'client')
import nuxt_plugin_stickyclient_2f4c5996 from 'nuxt_plugin_stickyclient_2f4c5996' // Source: ../frontend/plugins/vue-directives/sticky.client.js (mode: 'client')
import nuxt_plugin_clickparticlesclient_3dfa22f1 from 'nuxt_plugin_clickparticlesclient_3dfa22f1' // Source: ../frontend/plugins/vue-directives/click-particles/click-particles.client.js (mode: 'client')
import nuxt_plugin_textareaautosizeclient_69f88f18 from 'nuxt_plugin_textareaautosizeclient_69f88f18' // Source: ../frontend/plugins/vue-directives/textarea-auto-size.client.js (mode: 'client')
import nuxt_plugin_lazyloadclient_36ac6562 from 'nuxt_plugin_lazyloadclient_36ac6562' // Source: ../frontend/plugins/vue-directives/lazy-load.client.js (mode: 'client')
import nuxt_plugin_scrolllockclient_65600e5b from 'nuxt_plugin_scrolllockclient_65600e5b' // Source: ../frontend/plugins/vue-directives/scroll-lock.client.js (mode: 'client')
import nuxt_plugin_globalloaderclient_a2724aca from 'nuxt_plugin_globalloaderclient_a2724aca' // Source: ../frontend/plugins/vue-directives/global-loader/global-loader.client.js (mode: 'client')
import nuxt_plugin_nuxtjsonld_0a1e2d98 from 'nuxt_plugin_nuxtjsonld_0a1e2d98' // Source: ../frontend/plugins/nuxt-jsonld (mode: 'all')
import nuxt_plugin_meta_1d050960 from 'nuxt_plugin_meta_1d050960' // Source: ./composition-api/meta.mjs (mode: 'all')

// Component: <ClientOnly>
Vue.component(ClientOnly.name, ClientOnly)

// TODO: Remove in Nuxt 3: <NoSsr>
Vue.component(NoSsr.name, {
  ...NoSsr,
  render (h, ctx) {
    if (process.client && !NoSsr._warned) {
      NoSsr._warned = true

      console.warn('<no-ssr> has been deprecated and will be removed in Nuxt 3, please use <client-only> instead')
    }
    return NoSsr.render(h, ctx)
  }
})

// Component: <NuxtChild>
Vue.component(NuxtChild.name, NuxtChild)
Vue.component('NChild', NuxtChild)

// Component NuxtLink is imported in server.js or client.js

// Component: <Nuxt>
Vue.component(Nuxt.name, Nuxt)

Object.defineProperty(Vue.prototype, '$nuxt', {
  get() {
    const globalNuxt = this.$root ? this.$root.$options.$nuxt : null
    if (process.client && !globalNuxt && typeof window !== 'undefined') {
      return window.$nuxt
    }
    return globalNuxt
  },
  configurable: true
})

Vue.use(Meta, {"keyName":"head","attribute":"data-n-head","ssrAttribute":"data-n-head-ssr","tagIDKeyName":"hid"})

const defaultTransition = {"name":"page","mode":"out-in","appear":false,"appearClass":"appear","appearActiveClass":"appear-active","appearToClass":"appear-to"}

const originalRegisterModule = Vuex.Store.prototype.registerModule

function registerModule (path, rawModule, options = {}) {
  const preserveState = process.client && (
    Array.isArray(path)
      ? !!path.reduce((namespacedState, path) => namespacedState && namespacedState[path], this.state)
      : path in this.state
  )
  return originalRegisterModule.call(this, path, rawModule, { preserveState, ...options })
}

async function createApp(ssrContext, config = {}) {
  const store = createStore(ssrContext)
  const router = await createRouter(ssrContext, config, { store })

  // Add this.$router into store actions/mutations
  store.$router = router

  // Fix SSR caveat https://github.com/nuxt/nuxt.js/issues/3757#issuecomment-414689141
  store.registerModule = registerModule

  // Create Root instance

  // here we inject the router and store to all child components,
  // making them available everywhere as `this.$router` and `this.$store`.
  const app = {
    head: {"meta":[],"link":[],"style":[],"script":[]},

    store,
    router,
    nuxt: {
      defaultTransition,
      transitions: [defaultTransition],
      setTransitions (transitions) {
        if (!Array.isArray(transitions)) {
          transitions = [transitions]
        }
        transitions = transitions.map((transition) => {
          if (!transition) {
            transition = defaultTransition
          } else if (typeof transition === 'string') {
            transition = Object.assign({}, defaultTransition, { name: transition })
          } else {
            transition = Object.assign({}, defaultTransition, transition)
          }
          return transition
        })
        this.$options.nuxt.transitions = transitions
        return transitions
      },

      err: null,
      dateErr: null,
      error (err) {
        err = err || null
        app.context._errored = Boolean(err)
        err = err ? normalizeError(err) : null
        let nuxt = app.nuxt // to work with @vue/composition-api, see https://github.com/nuxt/nuxt.js/issues/6517#issuecomment-573280207
        if (this) {
          nuxt = this.nuxt || this.$options.nuxt
        }
        nuxt.dateErr = Date.now()
        nuxt.err = err
        // Used in src/server.js
        if (ssrContext) {
          ssrContext.nuxt.error = err
        }
        return err
      }
    },
    ...App
  }

  // Make app available into store via this.app
  store.app = app

  const next = ssrContext ? ssrContext.next : location => app.router.push(location)
  // Resolve route
  let route
  if (ssrContext) {
    route = router.resolve(ssrContext.url).route
  } else {
    const path = getLocation(router.options.base, router.options.mode)
    route = router.resolve(path).route
  }

  // Set context to app.context
  await setContext(app, {
    store,
    route,
    next,
    error: app.nuxt.error.bind(app),
    payload: ssrContext ? ssrContext.payload : undefined,
    req: ssrContext ? ssrContext.req : undefined,
    res: ssrContext ? ssrContext.res : undefined,
    beforeRenderFns: ssrContext ? ssrContext.beforeRenderFns : undefined,
    beforeSerializeFns: ssrContext ? ssrContext.beforeSerializeFns : undefined,
    ssrContext
  })

  function inject(key, value) {
    if (!key) {
      throw new Error('inject(key, value) has no key provided')
    }
    if (value === undefined) {
      throw new Error(`inject('${key}', value) has no value provided`)
    }

    key = '$' + key
    // Add into app
    app[key] = value
    // Add into context
    if (!app.context[key]) {
      app.context[key] = value
    }

    // Add into store
    store[key] = app[key]

    // Check if plugin not already installed
    const installKey = '__nuxt_' + key + '_installed__'
    if (Vue[installKey]) {
      return
    }
    Vue[installKey] = true
    // Call Vue.use() to install the plugin into vm
    Vue.use(() => {
      if (!Object.prototype.hasOwnProperty.call(Vue.prototype, key)) {
        Object.defineProperty(Vue.prototype, key, {
          get () {
            return this.$root.$options[key]
          }
        })
      }
    })
  }

  // Inject runtime config as $config
  inject('config', config)

  if (process.client) {
    // Replace store state before plugins execution
    if (window.__NUXT__ && window.__NUXT__.state) {
      store.replaceState(window.__NUXT__.state)
    }
  }

  // Add enablePreview(previewData = {}) in context for plugins
  if (process.static && process.client) {
    app.context.enablePreview = function (previewData = {}) {
      app.previewData = Object.assign({}, previewData)
      inject('preview', previewData)
    }
  }
  // Plugin execution

  if (typeof nuxt_plugin_plugin_cd5d8334 === 'function') {
    await nuxt_plugin_plugin_cd5d8334(app.context, inject)
  }

  if (typeof nuxt_plugin_plugin_36ea5a64 === 'function') {
    await nuxt_plugin_plugin_36ea5a64(app.context, inject)
  }

  if (typeof nuxt_plugin_nuxtsvgsprite_37017acc === 'function') {
    await nuxt_plugin_nuxtsvgsprite_37017acc(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginutils_0e3ce33f === 'function') {
    await nuxt_plugin_pluginutils_0e3ce33f(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginrouting_28919cca === 'function') {
    await nuxt_plugin_pluginrouting_28919cca(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginmain_38d792e5 === 'function') {
    await nuxt_plugin_pluginmain_38d792e5(app.context, inject)
  }

  if (typeof nuxt_plugin_vuescreen_7a9c6aae === 'function') {
    await nuxt_plugin_vuescreen_7a9c6aae(app.context, inject)
  }

  if (typeof nuxt_plugin_libplugin19577b34_1e48d70a === 'function') {
    await nuxt_plugin_libplugin19577b34_1e48d70a(app.context, inject)
  }

  if (typeof nuxt_plugin_axios_de7d5b08 === 'function') {
    await nuxt_plugin_axios_de7d5b08(app.context, inject)
  }

  if (typeof nuxt_plugin_api_30d8db9c === 'function') {
    await nuxt_plugin_api_30d8db9c(app.context, inject)
  }

  if (typeof nuxt_plugin_cookieuniversalnuxt_41c30bb8 === 'function') {
    await nuxt_plugin_cookieuniversalnuxt_41c30bb8(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginstatic_38ae93e8 === 'function') {
    await nuxt_plugin_pluginstatic_38ae93e8(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_smoothscrollpluginclient_0fba1bad === 'function') {
    await nuxt_plugin_smoothscrollpluginclient_0fba1bad(app.context, inject)
  }

  if (process.server && typeof nuxt_plugin_pluginserver_3419fa40 === 'function') {
    await nuxt_plugin_pluginserver_3419fa40(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_pluginclient_633eee90 === 'function') {
    await nuxt_plugin_pluginclient_633eee90(app.context, inject)
  }

  if (typeof nuxt_plugin_vbemtransformer_9484f534 === 'function') {
    await nuxt_plugin_vbemtransformer_9484f534(app.context, inject)
  }

  if (typeof nuxt_plugin_distplugin767a6c2c_3f46251d === 'function') {
    await nuxt_plugin_distplugin767a6c2c_3f46251d(app.context, inject)
  }

  if (typeof nuxt_plugin_router_3bb588b3 === 'function') {
    await nuxt_plugin_router_3bb588b3(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_rootclassesclient_88396562 === 'function') {
    await nuxt_plugin_rootclassesclient_88396562(app.context, inject)
  }

  if (typeof nuxt_plugin_reactiverefs_cf95474e === 'function') {
    await nuxt_plugin_reactiverefs_cf95474e(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_mittclient_46226e20 === 'function') {
    await nuxt_plugin_mittclient_46226e20(app.context, inject)
  }

  if (typeof nuxt_plugin_reactiveprovideinject_5c9b8e88 === 'function') {
    await nuxt_plugin_reactiveprovideinject_5c9b8e88(app.context, inject)
  }

  if (typeof nuxt_plugin_yametrika_ccebf046 === 'function') {
    await nuxt_plugin_yametrika_ccebf046(app.context, inject)
  }

  if (typeof nuxt_plugin_modals_1d9973ca === 'function') {
    await nuxt_plugin_modals_1d9973ca(app.context, inject)
  }

  if (typeof nuxt_plugin_vueformulate_6af646c6 === 'function') {
    await nuxt_plugin_vueformulate_6af646c6(app.context, inject)
  }

  if (typeof nuxt_plugin_notifications_15a88b85 === 'function') {
    await nuxt_plugin_notifications_15a88b85(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_viewportclient_dea6660c === 'function') {
    await nuxt_plugin_viewportclient_dea6660c(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_broadcastclient_5e5c0229 === 'function') {
    await nuxt_plugin_broadcastclient_5e5c0229(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_whatinputclient_a056fe80 === 'function') {
    await nuxt_plugin_whatinputclient_a056fe80(app.context, inject)
  }

  if (typeof nuxt_plugin_storesfastaccess_219f697d === 'function') {
    await nuxt_plugin_storesfastaccess_219f697d(app.context, inject)
  }

  if (typeof nuxt_plugin_resizeapi_1f2874f6 === 'function') {
    await nuxt_plugin_resizeapi_1f2874f6(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vuebarclient_f80be602 === 'function') {
    await nuxt_plugin_vuebarclient_f80be602(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_clickoutsideclient_75a81c60 === 'function') {
    await nuxt_plugin_clickoutsideclient_75a81c60(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_stickyclient_2f4c5996 === 'function') {
    await nuxt_plugin_stickyclient_2f4c5996(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_clickparticlesclient_3dfa22f1 === 'function') {
    await nuxt_plugin_clickparticlesclient_3dfa22f1(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_textareaautosizeclient_69f88f18 === 'function') {
    await nuxt_plugin_textareaautosizeclient_69f88f18(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_lazyloadclient_36ac6562 === 'function') {
    await nuxt_plugin_lazyloadclient_36ac6562(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_scrolllockclient_65600e5b === 'function') {
    await nuxt_plugin_scrolllockclient_65600e5b(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_globalloaderclient_a2724aca === 'function') {
    await nuxt_plugin_globalloaderclient_a2724aca(app.context, inject)
  }

  if (typeof nuxt_plugin_nuxtjsonld_0a1e2d98 === 'function') {
    await nuxt_plugin_nuxtjsonld_0a1e2d98(app.context, inject)
  }

  if (typeof nuxt_plugin_meta_1d050960 === 'function') {
    await nuxt_plugin_meta_1d050960(app.context, inject)
  }

  // Lock enablePreview in context
  if (process.static && process.client) {
    app.context.enablePreview = function () {
      console.warn('You cannot call enablePreview() outside a plugin.')
    }
  }

  // Wait for async component to be resolved first
  await new Promise((resolve, reject) => {
    // Ignore 404s rather than blindly replacing URL in browser
    if (process.client) {
      const { route } = router.resolve(app.context.route.fullPath)
      if (!route.matched.length) {
        return resolve()
      }
    }
    router.replace(app.context.route.fullPath, resolve, (err) => {
      // https://github.com/vuejs/vue-router/blob/v3.4.3/src/util/errors.js
      if (!err._isRouter) return reject(err)
      if (err.type !== 2 /* NavigationFailureType.redirected */) return resolve()

      // navigated to a different route in router guard
      const unregister = router.afterEach(async (to, from) => {
        if (process.server && ssrContext && ssrContext.url) {
          ssrContext.url = to.fullPath
        }
        app.context.route = await getRouteData(to)
        app.context.params = to.params || {}
        app.context.query = to.query || {}
        unregister()
        resolve()
      })
    })
  })

  return {
    store,
    app,
    router
  }
}

export { createApp, NuxtError }
