
	/* eslint-disable vue/no-unused-properties -- Used by Nuxt internally */

	import _prop from 'vue-types';
	import { mapStores } from 'pinia';
	import { useEntityStore } from '~stores/index';

	export default {
		name: 'the-page-loader',
		data: () => ({
			loading: false,
		}),
		computed: {
			...mapStores(useEntityStore),
		},
		methods: {
			start() {
				this.entityStore.loading = true;
				this.loading = true;
			},

			finish() {
				const self = this;
				setTimeout(() => {
					this.entityStore.loading = false;
					self.loading = false;
				}, 50); // a bit better UX
			},
		},
	};
