
	import _prop from 'vue-types';

	export default {
		name: 'footer-copyright',
		components: {},
		props: {},
		data: () => ({}),
		computed: {
			cCopyright() {
				return `© ${new Date().getFullYear()} UFLOR`;
			},
		},
		methods: {},
	};
