
	import _prop from 'vue-types';
	import FooterNavLi from './_footer-nav-li/footer-nav-li.vue';

	export default {
		name: 'footer-nav',
		components: { FooterNavLi },
		props: {
			items: _prop.array,
		},
		data: () => ({}),
		computed: {},
		methods: {},
	};
