
	import _prop from 'vue-types';

	import AppArrowButton from '~components/_app/app-arrow-button/app-arrow-button.vue';
	import HeaderNavLi from './_header-nav-li/header-nav-li.vue';

	export default {
		name: 'header-nav',
		components: { HeaderNavLi, AppArrowButton },
		props: {
			items: _prop.array.isRequired,
		},
		data: () => ({
			isExpanded: false,
		}),
		computed: {},
		methods: {},
	};
