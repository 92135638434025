/* eslint-disable unicorn/filename-case -- Nuxt limitation */
const isRouteChanged = (to, from) => to.path !== from.path;
const isHashChanged = (to, from) => to.path === from.path && to.hash !== from.hash;
const isOnlyGetChanged = (to, from) => to.path === from.path && to.hash === from.hash && from.fullPath !== to.fullPath;
const shouldScrollToTop = () => !window.$nuxt.$router.__preventScroll;

// If the returned position is falsy or an empty object,
// will retain current scroll position.
export default function routerScrollBehavior(to, from, savedPosition) {
	// No need to perform any scroll.
	if (isOnlyGetChanged(to, from)) return false;

	// `savedPosition` is only available for popstate navigation.
	const position = savedPosition
		|| ((isRouteChanged(to, from) && shouldScrollToTop()) ? { x: 0, y: 0 } : false);

	const { $nuxt } = window;

	if (!isRouteChanged(to, from) || isHashChanged(to, from)) {
		$nuxt.$nextTick(() => $nuxt.$emit('triggerScroll'));
	}

	return new Promise((resolve) => {
		$nuxt.$once('triggerScroll', () => {
			if (to.hash) {
				$nuxt.$scrollTo(to.hash || 0).catch(e => {
					console.warn('Can\'t scroll to `#' + to.hash + '`');
				});
				return resolve(false);
			}

			position && $nuxt.$scrollTo([position.x, position.y]);
			return resolve(false);
		});
	});
}
