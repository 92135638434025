
	import _prop from 'vue-types';

	export default {
		name: 'common-sandwich-button',
		props: {
			active: _prop.bool.def(false),
		},
		emits: ['click'],
		data: () => ({}),
		computed: {},
		methods: {},
	};
