
	import _prop from 'vue-types';
	import { mapStores } from 'pinia';
	import CatalogNavRoot from './_catalog-nav-root/catalog-nav-root.vue';
	import { useEntityStore } from '~stores/index';

	export default {
		name: 'catalog-nav',
		components: { CatalogNavRoot },
		props: {},
		data: () => ({
			dDefaultImage: {
				src: require('./images/default.svg'),
				alt: '',
			},
		}),
		computed: {
			...mapStores(useEntityStore),

			cItems() {
				return this.$layout.navCatalog.items;
			},

			isSectionsExists() {
				return !!this.$layout.navCatalog?.items.length;
			},

			cDefaultImage() {
				return this.entityStore.current.entity === 'page-index'
					? { src: require('./images/default-large.svg'), alt: '' }
					: { src: require('./images/default.svg'), alt: '' };
			},
		},
		methods: {},
	};
