import { useEntityStore, useGlobalStore } from '~stores/index.js';

export const actions = {
	async nuxtServerInit(store, context) {
		const entityStore = useEntityStore(context.$pinia);

		await Promise.all([
			context.route.path.includes('personal') && store.dispatch('user/init'),
			useGlobalStore(context.$pinia).init(),
		]);

		const [currentData] = await Promise.all([
			entityStore.init(),
			store.dispatch('layout/init', context),
			store.dispatch('siteLinks/init', context),
		]);

		entityStore.setCurrent(currentData);
	},
};
