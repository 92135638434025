const STATUS_ENTERING = 'entering';
const STATUS_IN = 'in';
const STATUS_LEAVING = 'leaving';
const STATUS_LEAVE = 'leave';

const ENDPOINT = '/favorites-items/';

export const state = () => ({
	items: [],
});

export const mutations = {
	SET_ITEMS: (state, items) => state.items = items,
	SET_STATUS: (state, { id, status }) => state.items.find(i => i.id === id).status = status,

	ADD_ITEM: (state, id) => state.items.push({ id, status: STATUS_ENTERING }),
	DELETE_ITEM: (state, id) => state.items = state.items.filter(i => i.id !== id),
};

export const actions = {
	async init({ commit }) {
		const items = await this.$api.get(ENDPOINT);

		commit('SET_ITEMS', (items || []).map(item => ({ ...item, status: STATUS_IN })));
	},

	async _handleResponse({ commit, state, dispatch }, { action, response, id, image }) {
		if (!response?.success) {
			(action === 'add') && commit('DELETE_ITEM', id);
			(action === 'remove') && commit('SET_STATUS', { id, status: STATUS_IN });
			dispatch('openNotification', response?.message);
		}

		const idsInStore = state.items.filter(i => i.status === STATUS_IN).map(i => i.id);
		const idsInResponse = response.items.map(i => i.id);

		const addedItems = response.items.filter(item => !idsInStore.includes(item.id));
		const removedItems = state.items.filter(item => !idsInResponse.includes(item.id));

		commit('SET_ITEMS', (response.items || []).map(item => ({ ...item, status: STATUS_IN })));

		addedItems.forEach(item => this.$notifications.add({ image: item.image, type: 'favorites', status: STATUS_IN }));
		removedItems.forEach(item => this.$notifications.add({ image: item.image, type: 'favorites', status: STATUS_LEAVE }));

		window.$nuxt.$broadcastChannel.postMessage('favorites');
	},

	async add({ state, commit, dispatch }, { id, image }) {
		commit('ADD_ITEM', id);
		const response = await this.$api.post(ENDPOINT, { id });
		dispatch('_handleResponse', { action: 'add', response, id, image });
	},

	async remove({ state, commit, dispatch }, { id, image }) {
		commit('SET_STATUS', { id, status: STATUS_LEAVING });
		const response = await this.$api.delete(ENDPOINT, { id });
		dispatch('_handleResponse', { action: 'remove', response, id, image });
	},

	openNotification(store, data) {
		const title = data?.title || 'Ошибка добавления в избранное';
		const text = data?.text || 'Упс, что-то пошло не так.';

		window.$nuxt.$modals.open('notification', {
			bind: { title, text },
		});
	},
};

export const getters = {
	items: (state) => state.items,
	hasItems: (state) => !!state.items.length,

	isInFavorites: (state) => state.items
		.filter(i => i.status === STATUS_IN)
		.reduce((acc, { id }) => ({ ...acc, [id]: true }), {}),

	isPending: (state) => state.items
		.filter(i => i.status !== STATUS_IN)
		.reduce((acc, { id }) => ({ ...acc, [id]: true }), {}),

	isActive: (state) => state.items
		.filter(i => [STATUS_IN].includes(i.status))
		.reduce((acc, { id }) => ({ ...acc, [id]: true }), {}),

	count: (state, { isActive }) => Object.keys(isActive).length,
};
