import Vue from 'vue';
import idlize from 'idle-until-urgent';
import { mergeObjects } from '@morev/utils';

const store = new WeakMap();

const showParticles = (options, modifiers) => {
	options = mergeObjects({ small: false }, options);
	return ({ currentTarget }) => {
		(options.small) && (currentTarget.dataset.particlesSize = 'small');
		currentTarget.dataset.clickParticles = '';
		currentTarget.dataset.clickParticles = 'is-animating';
		setTimeout(() => {
			currentTarget.dataset.clickParticles = '';
			currentTarget.dataset.particlesSize = '';
		}, 600);
	};
};

Vue.directive('click-particles', {
	bind(el, { value, modifiers }, vnode) {
		if (value === undefined) value = true;
		if (value === false) return;
		el.dataset.clickParticles = '';

		const handler = showParticles(value);
		store.set(el, handler);
		el.addEventListener('click', handler);
	},

	unbind(el, { value, modifiers }, vnode) {
		if (!store.has(el)) return;

		el.removeEventListener('click', store.get(el));
		store.delete(el);
	},
});

export default function (ctx, inject) {
	idlize(() => {
		import('./click-particles.scss' /* webpackChunkName: 'click-particles-styles' */);
	}, 3000);
}
