
	import _prop from 'vue-types';
	import { mapGetters } from 'vuex';
	import SafeNuxtLink from '~components/safe-nuxt-link/safe-nuxt-link.vue';

	export default {
		name: 'control-button',
		components: { SafeNuxtLink },
		props: {
			variant: _prop.oneOf(['search', 'favorites', 'cart', 'chat', 'scroll']).isRequired,
			background: _prop.oneOf(['default', 'none']),
			active: _prop.bool.def(false),
		},
		emits: ['click'],
		data: () => ({}),
		computed: {
			...mapGetters({
				favoritesCount: 'favorites/count',
				cartCount: 'cart/count',
			}),

			cComponent() {
				if (['favorites', 'cart'].includes(this.variant)) return 'safe-nuxt-link';
				return 'button';
			},

			cCount() {
				if (this.variant === 'cart') return this.cartCount;
				if (this.variant === 'favorites') return this.favoritesCount;
				return 0;
			},

			cIsActive() {
				return this.active || this.cCount !== 0;
			},

			cIcon() {
				if (this.variant === 'search') return 'search';
				if (this.variant === 'favorites') return 'heart';
				if (this.variant === 'cart') return 'bag';
				if (this.variant === 'chat') return 'chat';
				if (this.variant === 'scroll') return 'arrows/arrow-top-tailed';
				return null;
			},

			cBind() {
				if (this.variant === 'search') {
					return {
						'aria-label': this.$t('search-on-site'),
					};
				} else if (this.variant === 'cart') {
					return {
						'aria-label': this.$tc('cart', this.cartCount),
						'to': this.$siteLinks.cart,
					};
				} else if (this.variant === 'favorites') {
					return {
						'aria-label': this.$tc('favorites', this.favoritesCount),
						'to': this.$siteLinks.favorites,
					};
				} else if (this.variant === 'chat') {
					return {
						'aria-label': this.$t('write-to-us'),
					};
				} else if (this.variant === 'scroll') {
					return {
						'aria-label': this.$t('scroll-to-top'),
					};
				}

				return {};
			},
		},
		methods: {},
	};
