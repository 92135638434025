
	import _prop from 'vue-types';

	import BaseSection from '~components/_base/base-section/base-section.vue';
	import BaseContainer from '~components/_base/base-container/base-container.vue';
	import CatalogNav from '~components/catalog-nav/catalog-nav.vue';
	import { useEntityStore } from '~stores/index.js';
	import { mapStores } from 'pinia';
	import AppSelector from '~components/_app/app-selector/app-selector.vue';

	export default {
		name: 'the-hero',
		components: {
			BaseSection,
			BaseContainer,
			CatalogNav,
			AppSelector,
		},
		props: {},
		data: () => ({
			isMobileImageLoaded: false,
			isDesktopImageLoaded: false,
			isMounted: false,
		}),
		computed: {
			...mapStores(useEntityStore),

			shouldRender() {
				return this.cVariant !== 'small'
					|| this.entityStore.current.entity === 'page-catalog-single';
			},

			shouldRenderDesktopImage() {
				return (!this.isMounted && (this.$device.isDesktop || this.$device.isTablet))
					|| (this.isMounted && this.$screen.phoneLandscape);
			},

			shouldRenderMobileImage() {
				return !this.shouldRenderDesktopImage;
			},

			cVariant() {
				return this.entityStore.current.pageInfo?.hero?.variant ?? 'small';
			},

			cBackgroundImage() {
				if (this.cVariant !== 'large') return;

				const { desktop, mobile } = this.entityStore.current.pageInfo.hero.images;
				const { desktop: desktopPreview, mobile: mobilePreview } = this.entityStore.current.pageInfo.hero.previews;

				const [desktopLqip, desktopSrcset, desktopSrc] = [
					desktopPreview,
					this.$_resizeAPI(desktop.src, [2040, 370], false),
					this.$_resizeAPI(desktop.src, [2040, 370], true),
				];

				const [mobileLqip, mobileSrcset, mobileSrc] = [
					mobilePreview,
					this.$_resizeAPI(mobile.src, [480, 400], false),
					this.$_resizeAPI(mobile.src, [480, 400], true),
				];

				return {
					desktop: {
						preview: { src: desktopLqip, alt: '' },
						full: { src: desktopSrc, srcset: desktopSrcset, alt: '' },
					},
					mobile: {
						preview: { src: mobileLqip, alt: '' },
						full: { src: mobileSrc, srcset: mobileSrcset, alt: '' },
					},
				};
			},
		},
		methods: {},
		mounted() {
			this.isMounted = true;
		},
	};
