
	export default {
		name: 'formulate-input-password',
		components: {},
		props: {
			context: {type: Object, required: true},
		},
		data: () => ({
			isPasswordShown: false,
			parentForm: null,
		}),
		computed: {
			cType() {
				return this.isPasswordShown ? 'text' : 'password';
			},
		},
		methods: {
			toggle() {
				this.isPasswordShown = !this.isPasswordShown;
			},
			show() {
				this.isPasswordShown = true;
			},
			hide() {
				console.log('hidden');
				this.isPasswordShown = false;
			},

			checkForm() {
				this.parentForm = this.$el.closest('form');
				if (!this.parentForm) return;

				const formulateForm = this.parentForm.__vue__;
				if (formulateForm) {
					const originalSubmitRaw = formulateForm.$listeners['submit-raw'] || formulateForm.$listeners['submitRaw'];
					if (originalSubmitRaw) {
						// formulateForm.$listeners['submit-raw'] = () => {
						// 	this.hide();
						// 	return originalSubmitRaw();
						// };
					}
				} else {
					this.parentForm.addEventListener('submit', this.hide);
				}
			},
		},
		mounted() {
			this.checkForm();
		},
	};
