import Vue from 'vue';

Vue.mixin({
	methods: {
		$_resizeAPI(src, size, onlyOneX = true) {
			const base = `/api-v2/thumbnail/?src=${src}`;
			const x1 = Array.isArray(size) ? [size[0] || null, size[1] || null] : [size, size];
			const x2 = [x1[0] * 2 || null, x1[1] * 2 || null];
			const x3 = [x1[0] * 3 || null, x1[1] * 3 || null];

			const sizes = onlyOneX ? [x1] : [x1, x2, x3];

			return sizes.reduce((acc, [w, h], index) => {
				const x = onlyOneX ? '' : ` ${index + 1}x`;
				let queryParams = ``;
				if (w) queryParams += `&w=${w}`;
				if (h) queryParams += `&h=${h}`;

				acc.push(`${base}${queryParams}${x}`);
				return acc;
			}, []).join(', ');
		},
	},
});
