
	import _prop from 'vue-types';
	import SafeNuxtLink from '~components/safe-nuxt-link/safe-nuxt-link.vue';

	export default {
		name: 'user-data',
		components: { SafeNuxtLink },
		props: {},
		data: (vm) => ({}),
		computed: {},
		watch: {},
		methods: {
			async onLogoutClick() {
				await this.$store.dispatch('user/logout');
				window.location.reload();
			},
		},
	};
