import { render, staticRenderFns } from "./base-form.vue?vue&type=template&id=783ccd56"
import script from "./base-form.vue?vue&type=script&lang=js"
export * from "./base-form.vue?vue&type=script&lang=js"
import style0 from "./base-form.scss?vue&type=style&index=0&prod&lang=scss&external"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./base-form.i18n.yaml?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fsrv%2Fwww%2Fuflor.ru%2Fhtdocs%2Frelease%2Ffrontend%2Fviews%2Fcomponents%2F_base%2Fbase-form%2Fbase-form.vue&lang=yaml&external"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TransitionExpand: require('/srv/www/uflor.ru/htdocs/node_modules/.vue-transitions/TransitionExpand.vue').default})
