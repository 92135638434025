
	import _prop from 'vue-types';

	export default {
		name: 'common-logo',
		props: {
			link: _prop.string,
			type: _prop.oneOf(['logo', 'logo-short']).def('logo'),
		},
		data: () => ({}),
		computed: {
			computedComponent() {
				return this.isLink ? 'a' : 'div';
			},

			computedBind() {
				return this.computedComponent === 'a'
					? { 'href': this.link, 'aria-label': this.$t('aria-label') }
					: {};
			},

			isLink() {
				return this.link && this.$route.path !== this.link;
			},
		},
		methods: {},
	};
