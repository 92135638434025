
	import _prop from 'vue-types';
	import BaseImg from '~components/_base/base-img/base-img.vue';

	export default {
		name: 'footer-images',
		components: { BaseImg },
		props: {},
		data: () => ({}),
		computed: {},
		methods: {},
	};
