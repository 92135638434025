
	import _prop from 'vue-types';

	export default {
		name: 'fixed-filters-button',
		inheritAttrs: false,
		components: {},
		props: {
			name: _prop.string,
			clearable: _prop.bool.def(false),
		},
		emits: ['click'],
		data: () => ({}),
		computed: {},
		methods: {},
	};
