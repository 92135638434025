import Vue from 'vue';
import idlize from 'idle-until-urgent';

const NO_SHOWN_DELAY = 200;
const MIN_SHOW_TIME = 600;

const elementsStore = new Map();

const addOverlay = (el, modifiers) => {
	if (el.querySelector('.global-loader-overlay')) return;
	el.classList.add('global-loader-container');

	const { position } = getComputedStyle(el);
	if (!['fixed', 'absolute', 'relative'].includes(position)) {
		el.classList.add('global-loader-container--relative');
	}

	el.classList.add('global-loader-container--transition');
	el.classList.add('global-loader-container--opacity');

	if (modifiers.skipLoader) return;
	const loader = document.createElement('div');
	loader.classList.add('global-loader-overlay');
	loader.classList.add('global-loader-overlay--active');
	modifiers.fixed && el.classList.add('global-loader-overlay--fixed');

	el.prepend(loader);
	// eslint-disable-next-line no-unused-expressions -- touch for correct transition work
	loader.offsetHeight;
};

const removeOverlay = (el, modifiers, time) => {
	const overlays = el.querySelectorAll('.global-loader-overlay');
	el.classList.remove('global-loader-container--opacity');
	overlays.forEach(o => o.classList.remove('global-loader-overlay--active'));

	setTimeout(() => {
		el.classList.remove('global-loader-container--transition');
		el.classList.remove('global-loader-container');
		overlays.forEach(o => o.remove());
	}, 100);
};

const runQueue = () => {
	elementsStore.forEach(({ modifiers }, el) => addOverlay(el, modifiers));
};

Vue.directive('global-loader', (el, { value, modifiers }, vnode) => {
	const fromStore = elementsStore.get(el);
	const time = Date.now();

	if (value && fromStore) return; // loader already queried
	if (!value && !fromStore) return; // initial false value

	if (value) {
		elementsStore.set(el, { time, fireTime: time + (modifiers.instant ? 0 : NO_SHOWN_DELAY), modifiers });
		setTimeout(runQueue, modifiers.instant ? 0 : NO_SHOWN_DELAY);
	} else {
		const { fireTime } = elementsStore.get(el);
		const timeDiff = time - fireTime;
		elementsStore.delete(el);
		if (time - fromStore.time < NO_SHOWN_DELAY) return; // no loader fired yet
		if (timeDiff > MIN_SHOW_TIME) {
			removeOverlay(el, modifiers);
		} else {
			setTimeout(() => removeOverlay(el, modifiers), MIN_SHOW_TIME - timeDiff);
		}
	}
});

export default function (ctx, inject) {
	idlize(() => {
		import('./global-loader.scss' /* webpackChunkName: 'global-loader-styles' */);
	}, 3000);
}
