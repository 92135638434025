
	import { deepEqual } from '@morev/utils';

	export default {
		name: 'formulate-input-text',
		components: {},
		props: {
			context: {type: Object, required: true},

			imask: {type: Object, default: () => ({})},
			unmaskModel: {type: Boolean, default: false},
		},
		data: () => ({
			imaskInstance: null,
		}),
		computed: {
			isMasked() {
				return !!Object.keys(this.imask).length;
			},
			cValue() {
				return this.isMasked
					? (this.imaskInstance?.value || this.context.model)
					: this.context.model;
			},
			cListeners() {
				return {
					...this.$listeners,
					input: (event) => {
						if (!this.isMasked) {
							this.context.model = event.target.value;
						}
						this.$listeners.input?.(event);
					},
				};
			},
		},
		watch: {
			imask(to, from) {
				if (deepEqual(to, from)) return;
				this.imaskInstance?.updateOptions(to);
				this.onAccept();
			},
			'context.model'(to, from) {
				if (!this.isMasked) return;
				if (!this.imaskInstance) return;
				this.imaskInstance.value = to.toString();
			},
		},
		methods: {
			onInput(e) {
				if (this.isMasked) return;
				this.context.model = e.target.value;
			},
			onAccept(e) {
				if (!this.imaskInstance) return;

				this.context.model = this.unmaskModel
					? this.imaskInstance.unmaskedValue
					: this.imaskInstance.value;
			},
			updateValue() {
				if (!this.imaskInstance) return;
				this.context.model = this.imaskInstance.value;
			},

			setValue(value) {
				this.context.model = value;
			},
		},
		async mounted() {
			if (!this.isMasked) return;

			const IMask = await import('imask' /* webpackChunkName: 'imask' */).then((m) => m.default || m);
			if (!IMask) return;

			this.imaskInstance = new IMask(this.$refs.input, this.imask);
			this.imaskInstance.on('accept', this.onAccept);

			this.onAccept();
		},
	};
