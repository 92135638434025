/* eslint-disable import/exports-last */
import { camelCase, formatSlashes, isEmpty, isNullish, isObject, isString, omit, queryStringify } from '@morev/utils';

export const ITERATOR_PREFIX = '__iterator__';

export const skipEmpty = (obj) => Object.fromEntries(
	Object.entries(obj).filter(([key, value]) => !isEmpty(value)),
);

export const skipNullish = (obj) => Object.fromEntries(
	Object.entries(obj).filter(([key, value]) => !isNullish(value)),
);

const makeLocalRequest = async (maybeRoute, params) => {
	const [route, ...routeParams] = maybeRoute.split('/');

	let controller = null;
	try {
		controller = await import(`../../frontend/local-api/${route}/${route}.js`).then((m) => m.default);
	} catch {}

	if (!controller) return null;

	const result = await controller(params, routeParams);

	let data = null;
	if (result.data) data = result.data;
	if (result.file) data = await import(`../../frontend/local-api/${route}/${result.file}`).then(m => m.default);

	if (result.callback) return result.callback(data);
	return data;
};

const keyPart = (part, index) => (/\[.*]/.test(part) ? part : `[${ITERATOR_PREFIX}${index}] ${part}`);

const normalizeRequests = (iterator = 0, ...queries) => {
	let requests = {};

	for (let i = 0, l = queries.length; i < l; i++) {
		iterator++;
		const [part, nextPart] = [queries[i], queries[i + 1]];

		if (isString(part)) {
			requests[keyPart(part, iterator)] = isObject(nextPart) ? nextPart : {};
			isObject(nextPart) && i++;
		} else if (Array.isArray(part)) {
			requests = { ...requests, ...normalizeRequests(iterator - 1, ...part) };
		} else if (isObject(part)) {
			requests = { ...requests, ...part };
		}
	}

	return requests;
};

const parseRequests = (requests) => {
	return Object.keys(requests).reduce((acc, prop) => {
		const request = {
			local: false,
			key: null,
			route: null,
			params: {},
		};

		// Get [key] from square brackets if presented
		if (prop.match(/^\[(.*?)]\s?/g)) {
			const exec = /^\[(.*?)]\s?/.exec(prop);
			request.route = prop.replace(exec[0], '');
			request.key = exec[1];
		// Otherwise camelCased text after last slash
		} else {
			request.key = camelCase(prop.split('/').pop());
		}

		request.route = formatSlashes(request.route || prop, { start: false, end: false });

		// Detect local data requests via 'local/' prefix
		request.local = request.route.startsWith('local/');
		request.local && (request.route = request.route.replace('local/', ''));

		request.params = requests[prop];

		acc.push(request);
		return acc;
	}, []);
};

export const toCompositeData = (data) => {
	//
	data = {
		data: data.data.map(i => {
			const temp = { ...i };
			temp['method'] = i.route;
			delete temp.route;
			return temp;
		}),
		...omit(data, 'data'),
	};
	//
	data.data.forEach((item) => {
		item.params = skipNullish(item.params ?? {});
	});
	return queryStringify(data);
};

export const normalizeQuery = (...query) => {
	return parseRequests(normalizeRequests(0, ...query));
};

export const localRequest = async (requests = []) => {
	const queries = requests.reduce((acc, { key, route, params }) => {
		acc.push(makeLocalRequest(route, params).then(r => ({ [key]: r })));
		return acc;
	}, []);

	const result = await Promise.all(queries);

	return result.reduce((acc, curr) => {
		const key = Object.keys(curr)[0];
		acc[key] = curr[key];
		return acc;
	}, {});
};

export const flattenCompositeResponse = (data) => {
	if (!data) return {};
	return Object.keys(data).reduce((acc, key) => {
		acc[key] = data[key].result ? data[key].data : null;
		return acc;
	}, {});
};
