import { render, staticRenderFns } from "./fixed-filters.vue?vue&type=template&id=43cf0ff9"
import script from "./fixed-filters.vue?vue&type=script&lang=js"
export * from "./fixed-filters.vue?vue&type=script&lang=js"
import style0 from "./fixed-filters.scss?vue&type=style&index=0&prod&lang=scss&external"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./fixed-filters.i18n.yaml?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fsrv%2Fwww%2Fuflor.ru%2Fhtdocs%2Frelease%2Ffrontend%2Fviews%2Fcomponents%2Ffixed-filters%2Ffixed-filters.vue&lang=yaml&external"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TransitionFade: require('/srv/www/uflor.ru/htdocs/node_modules/.vue-transitions/TransitionFade.vue').default})
