import { defineStore } from 'pinia';
import { enhanceNuxtHead } from '@runway-frontend/nuxt-head';
import { sleep, isLighthouse, deepClone } from '@morev/utils';
import { isContentOnlyPage } from '~/scripts/helpers';

const LOCAL_ENTITIES = [];

const getEntityFor = async ({ context, path }) => {
	const { $api, error } = context;
	let entityResponse = await $api.get('/entity', { path });

	if (!entityResponse || entityResponse.entity === 'page-404') {
		entityResponse = await $api.get('local/entity', { path });
	}

	if (entityResponse.entity === 'redirect') {
		// eslint-disable-next-line sonarjs/no-empty-collection -- For future paths
		if (LOCAL_ENTITIES.includes(path)) {
			entityResponse = await $api.get('local/entity', { path });
		} else {
			if (entityResponse.url.startsWith('http')) {
				if (process.server || typeof window === 'undefined') {
					return {
						__redirectStatus: entityResponse.status || 307,
						__redirectUrl: entityResponse.url,
					};
				}

				const noPortOrigin = window.location.origin.replace(/:\d*$/, '');

				const relative = entityResponse.url.startsWith(noPortOrigin)
					? entityResponse.url.replace(noPortOrigin, '')
					: null;

				// Нужно, чтобы работала кнопка "Назад" в браузере
				if (relative) {
					window.location.href = path;
					// Чтобы Nuxt не вывалился 404 ошибкой из-за отсутствия entity, пока загружается не-nuxt страница
					await sleep(10000);
					return;
				}
			}

			return {
				__redirectStatus: entityResponse.status || 307,
				__redirectUrl: entityResponse.url,
			};
		}
	}

	if (
		process.env.NODE_ENV === 'production'
		&& !isLighthouse(context.req?.headers?.['user-agent'])
		&& !isContentOnlyPage(context.query)
	) {
		entityResponse.head = enhanceNuxtHead(entityResponse.head, entityResponse.scripts, 0);
	}

	delete entityResponse.scripts;

	(entityResponse.entity === 'page-404') && error({ statusCode: 404, head: entityResponse.head });

	return entityResponse;
};

export const useEntityStore = defineStore('entity', {
	state: () => ({
		loading: false,
		initializedOnClientSide: false,
		prev: null,
		current: null,
	}),

	actions: {
		async init() {
			if (!this.initializedOnClientSide && this.current && this.current.entity !== 'page-404') {
				return this.current;
			}

			const context = this.$nuxt;
			const { path } = context.route;

			const [data] = await Promise.all([
				getEntityFor({ context, path }),
				sleep(process.server ? 0 : 500),
			]);

			return data;
		},

		setCurrent(currentData) {
			this.prev = deepClone(this.current);
			this.current = currentData;
		},

		setLoading(isLoading) {
			this.loading = isLoading;
		},

		setInitialized() {
			this.initializedOnClientSide = true;
		},
	},

	getters: {},
});
