import Vue from 'vue';

export const state = () => ({});

export const mutations = {
	SET_STATE: (state, data) => Object.entries(data).forEach(([prop, val]) => Vue.set(state, prop, val)),
};

export const actions = {
	async init(store, ctx) {
		const query = await this.$api.get({
			'[index] /link': { entity: 'page-index' },
			'[catalog] /link': { entity: 'page-catalog' },
			'[favorites] /link': { entity: 'page-favorites' },
			'[cart] /link': { entity: 'page-cart' },
			'[checkout] /link': { entity: 'page-checkout' },
			'[search] /link': { entity: 'page-search' },
			'[personalAuth] local/link': { entity: 'page-personal-auth' },
			'[personalOrders] local/link': { entity: 'page-personal-orders' },
			'[reviews] local/link': { entity: 'page-reviews' },
			'[reviewsForm] local/link': { entity: 'page-reviews-form' },
			'[improvementForm] local/link': { entity: 'page-improvement-form' },
		});

		const data = Object.fromEntries(
			Object.entries(query).map(([key, val]) => [key, val?.link || '']),
		);

		const runtimeConfig = ctx.$config?.axios || {};
		data.baseURL = process.browser
			? (runtimeConfig.browserBaseURL || runtimeConfig.baseURL || window.location.origin || 'https://uflor.ru/')
			: (runtimeConfig.baseURL || process.env._AXIOS_BASE_URL_ || 'https://uflor.ru/');

		store.commit('SET_STATE', data);
	},
};
