
	import _prop from 'vue-types';
	import { getPixel } from '~/scripts/helpers';

	export default {
		name: 'base-img',
		props: {
			src: _prop.string,
			alt: _prop.string,
			srcset: _prop.string,
			pixel: _prop.oneOfType([String, Number, Array]).def(1),
			resize: _prop.oneOfType([Boolean, String, Number, Array]).def(false),
			lazy: _prop.bool.def(false),
			preloader: _prop.bool.def(false),
			preloaderBackground: _prop.bool.def(false),
		},
		data: () => ({}),
		computed: {
			computedBind() {
				const base = {
					'src': this.lazy ? getPixel(this.pixel) : this.getResizedSrc(true),
					'data-src': this.lazy ? this.getResizedSrc(true) : undefined,
					'alt': this.alt,
				};

				if (this.resize !== false) {
					base.srcset = this.lazy ? getPixel(this.pixel) : this.getResizedSrc(false);
					base['data-srcset'] = this.lazy ? this.getResizedSrc(false) : undefined;
				}

				return base;
			},
		},
		methods: {
			getResizedSrc(onlyOneX) {
				return (this.resize === false) ? this.src : this.$_resizeAPI(this.src, this.resize, onlyOneX);
			},
		},
	};
