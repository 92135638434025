/* eslint-disable no-console */

/**
 * Comments before lodash templates used intentionally to prevent ESLint errors
 */

import { formatSlashes, isString, omit, pick } from '@morev/utils';
import { flattenCompositeResponse, ITERATOR_PREFIX, localRequest, normalizeQuery, skipEmpty, toCompositeData } from './api-helpers.js';

//
import ApiPrinter from './api-printer.js';

//

//
// eslint-disable-next-line import/no-unresolved
import ApiDecorator from '../../frontend/api-decorator.mjs';

// eslint-disable-line import/no-unresolved
//

class Api {
	#ctx = null;

	#apiUri = '/api-v2/';

	#compositeUri = '/api-v2/composite/';

	printerInstance = null;

	#decoratorInstance = null;

	constructor(ctx) {
		this.#ctx = ctx;

		/*  */
		this.printerInstance = new ApiPrinter(ctx);
		/*  */

		/*  */
		this.#decoratorInstance = ApiDecorator;
		/*  */

		this.#decoratorInstance?.constructorDecorators.forEach(decorator => decorator(ctx));
	}

	async get(...query) {
		const requests = normalizeQuery(...query);
		const returnType = (requests.length === 1 && (requests[0].key.startsWith(ITERATOR_PREFIX) || isString(query[0])))
			? 'single'
			: 'multiple';

		const localRequests = requests.filter(i => i.local);
		const axiosRequests = requests.filter(i => !i.local).map(i => omit(i, 'local'));

		const localData = localRequests.length ? await this.#localRequest(localRequests) : {};

		const initialData = { data: [...axiosRequests] };
		const data = this.#decoratorInstance?.compositeDecorators.reduce((acc, decorator) => {
			const extra = decorator(acc, this.#ctx);
			extra && Object.entries(extra || {}).forEach(([key, value]) => {
				acc[key] = value;
			});
			return acc;
		}, initialData) || initialData;

		const axiosData = axiosRequests.length
			? await (this.#request({
				method: 'post',
				url: this.#compositeUri,
				data,
				isComposite: true,
				requests,
			})
				.catch(e => {
					console.log(e);
					console.log('Axios request failed:', axiosRequests);
					return {};
				}))
			: {};

		const merged = { ...flattenCompositeResponse(axiosData), ...localData };

		return returnType === 'single' ? Object.values(merged)[0] : merged;
	}

	async post(url, data) {
		return this.#request({ method: 'post', url, data, isComposite: false });
	}

	async patch(url, data) {
		return this.#request({ method: 'patch', url, data, isComposite: false });
	}

	async put(url, data) {
		return this.#request({ method: 'put', url, data, isComposite: false });
	}

	async delete(url, data) {
		return this.#request({ method: 'delete', url, data, isComposite: false });
	}

	async #request(_options) {
		const [options, appliedRequestDecorators] = this.#applyDecorators('request', _options);
		options.url = this.#prependApiUri(options.url);

		const config = omit(options, 'isComposite', 'requests');

		if (options.isComposite) {
			config.headers = { 'Content-Type': 'application/x-www-form-urlencoded' };
			config.data = toCompositeData(config.data);
		}

		config.headers = skipEmpty({
			...config.headers,
			...(process.server ? { 'User-Agent': this.#ctx?.req?.headers?.['user-agent'] } : {}),
			...(process.server ? { Accept: this.#ctx?.req?.headers?.accept } : {}),
		});

		let response = await this.#ctx.$axios(config)
			.then(response => response.data.data)
			.catch(e => {
				console.error(e);
				return {};
			});

		let appliedresponseDecorators = [];
		[response, appliedresponseDecorators] = this.#applyDecorators('response', options, response);

		this.printerInstance?.processRequest({
			...options,
			response,
			requestDecorators: appliedRequestDecorators,
			responseDecorators: appliedresponseDecorators,
		});

		return response;
	}

	async #localRequest(localRequests) {
		const response = await localRequest(localRequests);
		this.printerInstance?.processRequest({
			requests: localRequests,
			isComposite: true,
			response,
		});
		return response;
	}

	#applyDecorators(type, requestOptions, response) {
		let toReturn = type === 'request' ? requestOptions : response;
		if (
			!this.#decoratorInstance
			|| (type === 'request' && !this.#decoratorInstance.requestDecorators.length)
			|| (type === 'response' && !this.#decoratorInstance.responseDecorators.length)
		) return [toReturn, []];

		const appliedDecorators = [];

		if (requestOptions.isComposite) {
			type === 'request' && requestOptions.requests.forEach((request, index) => {
				this.#decoratorInstance.requestDecorators.forEach(({ decorator, shouldPrint }) => {
					const afterTransform = decorator({ method: 'get', ...pick(request, 'route', 'params') }, this.#ctx);
					if (afterTransform) {
						shouldPrint && appliedDecorators.push(request.key);

						// Replace inside Axios composite query
						const data = requestOptions.data.data.find(i => i.key === request.key);
						if (data) {
							data.route = afterTransform.route || request.route;
							data.params = afterTransform.params || request.params;
						}

						// Replace in data for printer
						toReturn.requests[index] = {
							...request,
							params: afterTransform.params,
							route: afterTransform.route,
						};
					}
				});
			});

			type === 'response' && requestOptions.requests.forEach((request) => {
				this.#decoratorInstance.responseDecorators.forEach(decorator => {
					const afterTransform = decorator({
						method: 'get',
						...pick(request, 'route', 'params'),
						response: response[request.key]?.data || null,
					}, this.#ctx);

					if (afterTransform) {
						response[request.key].result = true;
						response[request.key].data = afterTransform;
						appliedDecorators.push(request.key);
					}
				});
			});
		} else {
			type === 'request' && this.#decoratorInstance.requestDecorators.forEach(({ decorator, shouldPrint }) => {
				const afterTransform = decorator({
					method: requestOptions.method,
					route: formatSlashes(requestOptions.url.replace(this.#apiUri, ''), { start: false, end: false }),
					params: requestOptions.data,
				}, this.#ctx);

				if (afterTransform) {
					toReturn = {
						...requestOptions,
						url: this.#prependApiUri(afterTransform.route),
						data: afterTransform.params,
					};
				}
				shouldPrint && appliedDecorators.push(true);
			});

			type === 'response' && this.#decoratorInstance.responseDecorators.forEach(decorator => {
				const afterTransform = decorator({
					method: requestOptions.method,
					route: formatSlashes(requestOptions.url.replace(this.#apiUri, ''), { start: false, end: false }),
					params: requestOptions.data,
					response,
				}, this.#ctx);

				if (afterTransform) {
					toReturn = afterTransform;
					appliedDecorators.push(true);
				}
			});
		}

		return [toReturn, appliedDecorators];
	}

	#prependApiUri(url) {
		return url.startsWith(this.#apiUri) ? url : formatSlashes(`${this.#apiUri}${url}`, { end: true, to: '/' });
	}
}

const factory = (ctx) => new Api(ctx);

export default (ctx, inject) => {
	const api = factory(ctx);

	//
	const { router } = ctx.app;
	router.beforeEach((to, from, next) => {
		(to.path !== from.path) && api.printerInstance.startRequestsCollection();
		next();
	});
	router.afterEach((to, from) => {
		api.printerInstance.endRequestsCollection();
	});
	//

	inject('api', api);
};
