
	import _prop from 'vue-types';
	import { useCatalogFiltersStore, useCatalogFilteredStore } from '~stores/index.js';
	import { isNullish } from '@morev/utils';
	import { mapStores } from 'pinia';
	import FixedFiltersButton from './_fixed-filters-button/fixed-filters-button.vue';
	import { formatThousands } from '~/scripts/helpers';
	import { useEntityStore } from '~stores/index';

	export default {
		name: 'fixed-filters',
		components: { FixedFiltersButton },
		props: {},
		setup() {
			const catalogFiltersStore = useCatalogFiltersStore();
			const catalogFilteredStore = useCatalogFilteredStore();

			return {
				catalogFiltersStore,
				catalogFilteredStore,
			};
		},
		data: () => ({}),
		computed: {
			...mapStores(useEntityStore),

			items() {
				const filters = this.catalogFiltersStore.enhancedFilters.reduce((acc, filterObject) => {
					if (!filterObject.$meta.activeCount) return acc;

					filterObject.$meta.activeItems.forEach(item => {
						acc.push({
							$meta: {
								toggle: item.$meta.toggle,
							},
							name: item.name,
							clearable: !item.$meta.isStatic,
							variant: 'filter',
						});
					});

					// Если кастомная стоимость
					if (filterObject.type === 'range' && !filterObject.$meta.activeItems.length) {
						acc.push({
							$meta: {
								toggle: filterObject.$meta.reset,
							},
							name: this.getRangeName(filterObject.$meta.modelRange),
							clearable: true,
							variant: 'filter',
						});
					}

					return acc;
				}, []);

				const sort = this.catalogFilteredStore.sortOptions
					.find(o => o.$meta.isActive && o.value !== this.catalogFilteredStore.sortDefaultValue);

				if (sort) {
					return [
						{
							$meta: {
								toggle: () => this.catalogFilteredStore.setSort(this.catalogFilteredStore.sortDefaultValue),
							},
							name: sort.nameAsFilter || sort.name,
							clearable: true,
							variant: 'sort',
						},
						...filters,
					];
				}

				return filters;
			},
		},
		methods: {
			getRangeName(modelRange) {
				const { min, max } = modelRange;
				if (isNullish(min)) return this.$t('to', { value: formatThousands(max), currency: '₽' });
				if (isNullish(max)) return this.$t('from', { value: formatThousands(min), currency: '₽' });
				return [
					this.$t('from', { value: formatThousands(min), currency: '' }),
					this.$t('to', { value: formatThousands(max), currency: '₽' }),
				].join(' ');
			},

			onFilterClick(item) {
				const offset = this.$screen.tabletPortrait ? -24 : -16;
				this.$scrollTo('.catalog-filters', { offset });
				item.$meta.toggle();
			},
		},
	};
