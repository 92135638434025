import Vue from 'vue';

import fuzzysort from 'fuzzysort';

const URL_PROP = 'url';

export const state = () => ({});

export const mutations = {
	SET_DATA(state, data) {
		Object.keys(data).forEach(prop => {
			Vue.set(state, prop, data[prop]);
		});
	},
	SET_URL: (state, url) => Vue.set(state, URL_PROP, url),
};

export const actions = {
	async init(store, ctx) {
		const data = await this.$api.get({
			'[cookieNotification] /site-cookies': {},
			'[infoMessage] /site-info-message': {},
			'[navMain] /nav-main': {},
			'[navShort] /nav-short': {},
			'[navFooter] /nav-footer': {},
			'[navCatalog] /nav-catalog-v2': {},
			'[navPrivacy] /nav-privacy': {},
			'[navUser] /nav-user': {},
			'[cities] /site-cities': {},
			'[formSubscribe] /form': { id: 'subscribe' },
			'[counters] /site-counters': {},
			'[contacts] /site-contacts': {},
			'[social] /site-social': {},
			'[searchSuggestions] /search-suggestions': {},
			'[privacy] /site-privacy': {},
		});

		store.commit('SET_DATA', Object.freeze(data));
		store.commit('SET_URL', ctx.route.path);
	},
};

export const getters = {
	mail: (state) => state.contacts.mail,
	phones: (state) => state.contacts.phones,
	social: (state) => state.social,
	writeToUs: (state) => state.social?.writeToUsItems || [],
	cities: (state) => state.cities,
	mainCities: (state) => state.cities.filter(i => i.isMain),
	restCities: (state) => state.cities.filter(i => !i.isMain).sort((a, b) => (a.title > b.title ? 1 : -1)),
	dates: (state) => state.dates,
	searchSuggestions: ({ searchSuggestions }) => (query) => {
		return fuzzysort.go(query, searchSuggestions || [], { key: 'text' }).map(i => i.obj);
	},
	privacyLink: (state) => state.privacy.link,
};
