import { render, staticRenderFns } from "./base-button.vue?vue&type=template&id=0a8dd524"
import script from "./base-button.vue?vue&type=script&lang=js"
export * from "./base-button.vue?vue&type=script&lang=js"
import style0 from "./base-button.scss?vue&type=style&index=0&prod&lang=scss&external"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TransitionFade: require('/srv/www/uflor.ru/htdocs/node_modules/.vue-transitions/TransitionFade.vue').default})
