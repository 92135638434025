
	import _prop from 'vue-types';

	export default {
		name: 'base-button',
		components: {},
		props: {
			link: _prop.string,
			type: _prop.string.def('button'),
			variant: _prop.oneOf(['primary', 'vinous', 'raised', 'link']).def('primary'),
			size: _prop.oneOf(['default', 'large']).def('default'),
			icon: _prop.string,
			iconPrev: _prop.string,
			tag: _prop.string,
			loading: _prop.bool.def(false),
			block: _prop.bool.def(false),
			small: _prop.bool.def(false),
			medium: _prop.bool.def(false),
			fat: _prop.bool.def(false),
		},
		emits: ['click'],
		data: () => ({}),
		computed: {
			computedTag() {
				if (this.tag) return this.tag;
				return this.link
					? this.link.startsWith('/')
						? 'nuxt-link'
						: 'a'
					: 'button';
			},

			computedBind() {
				return {
					href: this.computedTag === 'button' ? false : this.link,
					to: this.computedTag === 'nuxt-link' ? this.link : false,
					type: this.computedTag === 'button' ? this.type : false,
				};
			},
		},
		methods: {},
	};
