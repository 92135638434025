
	import { isEmpty } from '@morev/utils';
	import _prop from 'vue-types';
	import BaseImg from '~components/_base/base-img/base-img.vue';
	import { computed, onMounted, ref, useContext } from '@nuxtjs/composition-api';
	import CatalogNavPrimary from '../_catalog-nav-primary/catalog-nav-primary.vue';
	import CatalogNavSecondary from '../_catalog-nav-secondary/catalog-nav-secondary.vue';
	import CatalogNavTertiary from '../_catalog-nav-tertiary/catalog-nav-tertiary.vue';

	export default {
		name: 'catalog-nav-root',
		components: { BaseImg, CatalogNavPrimary, CatalogNavSecondary, CatalogNavTertiary },
		props: {
			index: _prop.number.isRequired,
			label: _prop.string,
			icon: _prop.object,
			variant: _prop.oneOf(['rose', 'vinous']),
			primary: _prop.object,
			secondary: _prop.array,
		},
		setup($props, { refs }) {
			const { app: { $screen, $mitt } } = useContext();
			const isExpanded = ref(false);
			const activeSecondaryIndex = ref(0);

			const hasIcon = computed(() => !isEmpty($props.icon?.src));
			const hasPrimaryPart = computed(() => !isEmpty($props.primary.items) || !isEmpty($props.primary.link?.link));
			const hasPrimaryItems = computed(() => !isEmpty($props.primary.items));
			const hasPrimaryLink = computed(() => !isEmpty($props.primary.link?.link));
			const hasSecondaryPart = computed(() => !isEmpty($props.secondary));

			// eslint-disable-next-line unicorn/consistent-function-scoping -- Better to be here
			const hasItemIcon = (iconDeclaration) => !isEmpty(iconDeclaration?.src);

			const onRootClick = (index) => {
				if ($screen.tabletLandscape) {
					isExpanded.value = !isExpanded.value;
				} else {
					$mitt.emit('catalog-nav.click', index);
				}
			};

			const isMounted = ref(false);
			onMounted(() => (isMounted.value = true));

			return {
				isMounted,
				onRootClick,
				isExpanded,
				activeSecondaryIndex,
				//
				hasIcon,
				hasPrimaryPart,
				hasPrimaryItems,
				hasPrimaryLink,
				hasSecondaryPart,
				//
				hasItemIcon,
			};
		},

		watch: {
			isExpanded: {
				handler() { this.setTertiaryWidth(); },
			},
			activeSecondaryIndex: {
				handler() { this.setTertiaryWidth(); },
			},
		},

		methods: {
			async setTertiaryWidth() {
				await this.$nextTick();
				if (!this.$refs.tertiaryRef) return;

				const innerContent = this.$refs.tertiaryColumnsRef[this.activeSecondaryIndex]?.$el;
				if (!innerContent) return;

				const currentWidth = Math.ceil(innerContent.getBoundingClientRect().width) + 48;
				this.$refs.tertiaryRef.style.setProperty('width', `${currentWidth}px`);
			},
		},
	};
