
	import _prop from 'vue-types';
	import BaseForm from '~components/_base/base-form/base-form.vue';
	import FormField from '~components/_form/form-field/form-field.vue';

	export default {
		name: 'footer-subscribe',
		components: { BaseForm, FormField },
		props: {
			data: _prop.object,
		},
		data: () => ({}),
		computed: {
			computedFields() {
				return this?.data?.fields?.map(i => ({
					...i,
					modifiers: { onDark: true },
					innerModifiers: {
						input: { onDark: true },
					},
				}));
			},
		},
		methods: {},
	};
