
	import { clamp, isString } from '@morev/utils';

	export default {
		name: 'formulate-input-number',
		components: {},
		props: {
			context: {type: Object, required: true},
			min: {type: [Number, String], default: -Infinity},
			max: {type: [Number, String], default: +Infinity},
			step: {type: [Number, String], default: 1},
			strictStep: {type: Boolean, default: false},
			precision: {type: Number, default: 0},
		},
		emits: ['change'],
		data: () => ({
			userInput: null,
			currentValue: null,
		}),
		computed: {
			cMin() {
				if ([null, undefined].includes(this.min)) return -Infinity;
				if (!isString(this.min)) return this.min;
				const float = parseFloat(this.min);

				return Number.isNaN(float) ? -Infinity : float;
			},
			cMax() {
				if ([null, undefined].includes(this.max)) return +Infinity;
				if (!isString(this.max)) return this.max;
				const float = parseFloat(this.max);

				return Number.isNaN(float) ? +Infinity : float;
			},
			cSlot() {
				const {isMinDisabled, isMaxDisabled} = this;
				const {increase, decrease} = this;
				const {cMin, cMax, step, precision, strictStep} = this;
				return {
					value: this.cValue,

					isMinDisabled,
					isMaxDisabled,

					increase,
					decrease,

					min: cMin,
					max: cMax,
					step,
					precision,
					strictStep,
				};
			},
			isMinDisabled() {
				return this._decrease(this.cValue, this.step) < this.cMin;
			},
			isMaxDisabled() {
				return this._increase(this.cValue, this.step) > this.cMax;
			},
			cValue() {
				if (this.userInput !== null) return this.userInput;
				if (typeof this.context.model !== 'number') return this.context.model;

				const currentValue = this.withStrictPrecision(this.context.model);

				return (this.precision !== 0)
					? this.withDot(currentValue.toFixed(this.precision))
					: this.withDot(currentValue);
			},
			cPrecision() {
				const {value, step, getPrecision, precision} = this;
				const stepPrecision = getPrecision(step);
				if (precision === 0) return Math.max(getPrecision(value), stepPrecision);

				if (stepPrecision > precision) {
					console.warn(`[formulate-input-number] Precision shouldn't be less than the decimal places of step`);
				}

				return precision;
			},
		},
		watch: {
			'context.model': {
				immediate: true,
				handler(value) {
					let newVal = value === '' ? undefined : Number(value);
					if (Number.isNaN(newVal)) return;
					if (newVal !== undefined && this.precision !== 0) {
						newVal = this.toPrecision(newVal, this.precision);
					}
					newVal = this.withStrictPrecision(newVal);
					if (typeof newVal !== 'undefined') {
						newVal = clamp(newVal, this.cMin, this.cMax);
					}

					this.currentValue = newVal;
					this.userInput = null;
					this.context.model = newVal;
				},
			},
		},
		methods: {
			toPrecision(num, precision = this.cPrecision) {
				return parseFloat(Math.round(num * (10 ** precision))) / (10 ** precision);
			},
			withStrictPrecision(value) {
				if (!this.strictStep) return value;

				const stepPrecision = this.getPrecision(this.step);
				const precisionFactor = 10 ** stepPrecision;

				return Math.round(value / this.step) * precisionFactor * this.step / precisionFactor;
			},
			withDot(value) {
				if (!this.useDot) return value;
				return value.toString().replace(',', '.');
			},
			getPrecision(value) {
				if (!value) return 0;
				const valueString = value.toString();
				const dotPosition = valueString.indexOf('.');
				if (dotPosition === -1) return 0;

				return valueString.length - dotPosition - 1;
			},
			_decrease(val, step) {
				if (typeof val !== 'number' && val !== undefined) return this.currentValue;
				const precisionFactor = Math.pow(10, this.cPrecision);
				return this.toPrecision((precisionFactor * val - precisionFactor * step) / precisionFactor);
			},
			decrease() {
				if (this.disabled || this.isMinDisabled) return;
				const newVal = this._decrease(this.cValue || 0, this.step);
				this.setCurrentValue(newVal);
			},
			_increase(val, step) {
				if (typeof val !== 'number' && val !== undefined) return this.currentValue;
				const precisionFactor = Math.pow(10, this.cPrecision);
				return this.toPrecision((precisionFactor * val + precisionFactor * step) / precisionFactor);
			},
			increase() {
				if (this.disabled || this.isMaxDisabled) return;
				const newVal = this._increase(this.cValue || 0, this.step);
				this.setCurrentValue(newVal);
			},
			setCurrentValue(newVal) {
				const oldVal = this.currentValue;
				if (typeof newVal === 'number' && this.precision !== 0) {
					newVal = this.toPrecision(newVal, this.precision);
				}
				if (typeof newVal === 'undefined') {
					this.context.model = null;
					return;
				}
				newVal = clamp(newVal, this.cMin, this.cMax);
				if (oldVal === newVal) return;

				this.userInput = null;
				this.context.model = newVal;
				this.currentValue = newVal;
			},
			onInput(value) {
				this.userInput = value;
			},
			onChange(value) {
				const newVal = value === '' ? undefined : Number(value);
				this.setCurrentValue(newVal);
				this.$emit('change', newVal);
				this.userInput = null;
			},
			onMouseWheel(e) {

			},
		},
	};
