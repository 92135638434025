
	import { isEmpty } from '@morev/utils';
	import _prop from 'vue-types';
	import BaseImg from '~components/_base/base-img/base-img.vue';

	export default {
		name: 'footer-apps',
		components: { BaseImg },
		props: {},
		data: () => ({}),
		computed: {
			isAppsFilled() {
				return Object.keys(this.$layout?.contacts?.apps || {}).length;
			},

			hasQrCode() {
				return !isEmpty(this.$layout.contacts.qrCode?.src);
			},

			cItems() {
				return Object.entries(this.$layout.contacts.apps).map(([name, link]) => {
					const mobileIcon = (() => {
						if (name === 'appstore') return 'download/app-store';
						if (name === 'google') return 'download/google-play';
						return `download/${name}`;
					})();
					return {
						name,
						link,
						label: this.$t(`app-on-${name}`),
						mobileIcon,
						icon: `${mobileIcon}-small`,
					};
				});
			},
		},
		methods: {},
	};
