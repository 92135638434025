
	import * as Validators from 'vuelidate/lib/validators/index.js';
	import { validationMixin } from 'vuelidate';
	import { formDataToObject, isEmpty, deepClone } from '@morev/utils';

	import _prop from 'vue-types';

	import FormField from '~components/_form/form-field/form-field.vue';
	import BaseButton from '~components/_base/base-button/base-button.vue';
	import customValidators from './custom-validators.js';

	const isFunction = (v) => !!(v && v.constructor && v.call && v.apply);

	export default {
		name: 'base-form',
		inheritAttrs: false,
		mixins: [validationMixin],
		components: {
			FormField,
			BaseButton,
		},
		props: {
			data: _prop.object.isRequired,
			showHeader: _prop.bool.def(true),
			showSubmit: _prop.bool.def(true),
			showPrivacy: _prop.bool.def(true),
			showRequiredTip: _prop.bool.def(false),
			// Standard _prop.func don't allow `undefined` as default value, but we need it
			sendHandler: _prop.custom(isFunction, 'Send handler should be a function').def(undefined),
			successHandler: _prop.custom(isFunction, 'Success handler should be a function').def(undefined),
			errorHandler: _prop.custom(isFunction, 'Error handler should be a function').def(undefined),
			beforeSend: _prop.custom(isFunction, 'Before send checker should be a function').def(undefined),
			afterSend: _prop.custom(isFunction, 'After send action should be a function').def(undefined),
			afterSuccessSend: _prop.custom(isFunction, 'After success send action should be a function').def(undefined),
			afterErrorSend: _prop.custom(isFunction, 'After error send action should be a function').def(undefined),
		},
		validations() {
			this.fixRequiredCheckboxes();
			return { values: this.validationRules };
		},
		data() {
			return {
				form: null,
				isFormChecked: false,
				isPending: false,
				customValidators: {},
				values: {},
				isMounted: false,
			};
		},
		computed: {
			cSlotBind() {
				return {
					ref: this,
					privacyField: this.privacyField,
				};
			},

			thisContext() {
				return this;
			},

			privacyField() {
				return (this.form?.privacy && !isEmpty(this.form.privacy)) ? this.form.privacy : null;
			},

			validationRules() {
				if (!this.form.fields) return {};

				const toValidator = (params, validatorKey) => {
					if (params && !Array.isArray(params)) params = [params]; // To extract params properly
					// It's rough, but Vuelidate nulled validators dont work if u pass ever null as param :\
					return params
						? (Validators[validatorKey])
							? Validators[validatorKey](...params)
							: this.customValidators[validatorKey](...params).bind(this)
						: Validators[validatorKey] || this.customValidators[validatorKey];
				};

				return [...this.form.fields, this.privacyField].reduce((rules, field) => {
					if (!field?.validators) return rules;

					const validations = Object.keys(field.validators).reduce((acc, curr) => {
						const { params } = field.validators[curr];
						return { ...acc, [curr]: toValidator(params, curr) };
					}, {});

					return { ...rules, [field.name]: validations };
				}, {});
			},

			errorMessages() {
				if (!this.form.fields) return {};

				return [...this.form.fields, this.privacyField].reduce((messages, field) => {
					if (!field) return messages; // may be null cause privacyField literally optional
					const validator = this.$v.values[field.name];
					if (!validator) return messages;

					const rules = field.validators;
					const rulesKeys = Object.keys(rules);
					for (const rule of rulesKeys) {
						if (validator[rule] !== false) continue;
						messages[field.name] = [...messages[field.name] ?? [], rules[rule].message];
					}
					return messages;
				}, {});
			},

			formHasErrors() {
				return this?.form?.errors?.length > 0;
			},
		},
		methods: {
			// Checkbox 'required' validator doesn't work as expected, so there some hack
			fixRequiredCheckboxes() {
				[...(this.form.fields || []), this.privacyField].forEach(field => {
					if (!field?.validators?.required || field.type !== 'checkbox') return;

					field.validators.toBeTrue = {
						message: field.validators.required.message,
						params: null,
					};
					delete field.validators.required;
				});
			},

			input(field, value) {
				this.values[field.name] = value;
				this.reset(field);
			},

			update(field, value) {
				this.values[field.name] = value;
				this.touch(field);
			},

			reset(field) {
				this.$v.values[field.name] && this.$v.values[field.name].$reset();
				field.errors = null;
				// setTimeout(() => this.$v.form[key].$reset(), 500);
			},

			touch(field) {
				this.$v.values[field.name]?.$touch();
			},

			vBindField(field) {
				if (!field) return {};
				// Checkbox "key" property in fact - "checked", but we need to save value cause may be checkbox groups
				const value = (field.type === 'checkbox') ? field.value : this.values[field.name];
				const checked = (field.type === 'checkbox') ? this.values[field.name] : field.checked;

				return {
					...field,
					value,
					checked,
					vuelidate: this.$v.values[field.name],
					validatorsErrors: this.errorMessages[field.name],
				};
			},

			vOnField(field) {
				return {
					input: (e) => this.input(field, e),
					change: (e) => this.update(field, e),
					focus: (e) => this.reset(field),
					blur: (e) => this.touch(field),
				};
			},

			scrollToError() {
				setTimeout(() => {
					const firstError = this.$refs.form.querySelector('[class$="error"]');
					firstError && this.$scrollTo(firstError, {
						ifNeeded: true,
						block: 'center',
					});
				}, 400);
			},

			async onSubmit() {
				this.$v.values.$touch();
				if (this.$v.$invalid) {
					this.scrollToError();
					return;
				}

				const formData = formDataToObject(new FormData(this.$refs.form));

				// Before send hook
				if (this.beforeSend && !await this.beforeSend(this, formData) === false) return;

				// External send handler
				if (this.sendHandler) {
					this.sendHandler(this, formData);
					return;
				}

				this.isPending = true;
				const response = await this.$api[this.form.method](this.form.action, formData);
				this.isPending = false;

				// After send hook
				if (this.afterSend && await this.afterSend(this, response) === false) return;

				// After success send hook
				if (
					this.afterSuccessSend
					&& response.success === true
					&& await this.afterSuccessSend(this, response) === false
				) return;

				// After error send hook
				if (
					this.afterErrorSend
					&& response.success === false
					&& await this.afterErrorSend(this, response) === false
				) return;

				// External success submission handler
				if (this.successHandler && response.success === true) {
					this.successHandler(this, response.data);
					return;
				}

				// External error submission handler
				if (this.errorHandler && response.success === false) {
					this.errorHandler(this, response.data);
					return;
				}

				// Default behaviour
				this.form = response;
			},
		},

		created() {
			this.form = deepClone(this.data);

			if (this.form.fields && this.form.fields.length) {
				this.isFormChecked = true;
			} else {
				return;
			}

			this?.form?.fields?.forEach(field => {
				const value = field.type === 'checkbox' ? field.checked : field.value;
				this.$set(this.values, [field.name], value ?? null);
			});

			if (this.privacyField) {
				this.privacyField.type = 'checkbox';
				this.$set(this.values, [this.privacyField.name], this.privacyField.checked ?? null);
			}

			this.customValidators = customValidators(this);
		},
	};
