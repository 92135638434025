
	import _prop from 'vue-types';

	export default {
		name: 'app-arrow-button',
		components: {},
		props: {
			variant: _prop.oneOf(['default', 'colored', 'colored-inverse']).def('default'),
			active: _prop.bool.def(false),
		},
		emits: ['click'],
		data: (vm) => ({}),
		computed: {},
		watch: {},
		methods: {},
	};
