/**
 * A11y for keyboard things.
 * Dynamically adds specified classes when user navigates through tab button or arrows.
 *
 * @see https://github.com/ten1seven/what-input
 */

import idlize from 'idle-until-urgent';

import { isMobile } from '@morev/utils';

if (!isMobile()) {
	idlize(() => {
		import(`what-input` /* webpackChunkName: 'what-input' */)
			.then(m => m.default || m)
			.then(whatInput => {
				// https://github.com/ten1seven/what-input/blob/main/README.md#ignore-keys
				whatInput.ignoreKeys([
					16,
					17,
					18,
					91,
					93,
					27, // esc
					13, // enter
				]);
				window.WhatInput = whatInput;
			});
	});
}
