
	import _prop from 'vue-types';
	import CommonCrossButton from '~components/_common/common-cross-button/common-cross-button.vue';
	import ExternalScrollbar from '~components/_external/external-scrollbar/external-scrollbar.vue';
	import Modal from '~components/_modals/__modal/modal.vue';

	export default {
		name: 'modal-mobile',
		inheritAttrs: false,
		components: {
			Modal,
			ExternalScrollbar,
			CommonCrossButton,
		},
		props: {
			title: _prop.string.def(''),
			align: _prop.oneOf(['center', 'left']).def('left'),
			nonScrollable: _prop.bool.def(false),
			onlyClose: _prop.bool.def(false),
			padded: _prop.bool.def(false),
			modalClass: _prop.string.def(''),
		},
		data: () => ({}),
		computed: {
			cModalClass() {
				return [this.b(), this.modalClass];
			},
		},
		methods: {},
	};
