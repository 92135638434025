import { useFilteredStoreFactory } from '@runway-frontend/nuxt-pinia';
import { useGlobalStore } from './global-store.js';

export const filteredStoreParams = {
	extraRequestParams: (context, store) => {
		const globalStore = useGlobalStore();
		const { currency, lang } = context.store.state.user;

		return {
			date: globalStore.selectedDate,
			cityId: globalStore.cityId,
			currency,
			lang,
		};
	},
};

export const useCatalogFilteredStore = useFilteredStoreFactory('catalog', filteredStoreParams);
