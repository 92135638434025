
	import _prop from 'vue-types';

	import BaseSection from '~components/_base/base-section/base-section.vue';
	import BaseContainer from '~components/_base/base-container/base-container.vue';
	import FooterNav from './footer-nav/footer-nav.vue';
	import FooterSubscribe from './_footer-subscribe/footer-subscribe.vue';
	import FooterApps from './_footer-apps/footer-apps.vue';
	import FooterPrivacy from './_footer-privacy/footer-privacy.vue';
	import FooterImages from './_footer-images/footer-images.vue';
	import FooterSocial from './_footer-social/footer-social.vue';
	import FooterCopyright from './_footer-copyright/footer-copyright.vue';

	export default {
		name: 'the-footer',
		components: {
			BaseSection,
			BaseContainer,
			FooterNav,
			FooterSubscribe,
			FooterApps,
			FooterPrivacy,
			FooterImages,
			FooterSocial,
			FooterCopyright,
		},
		props: {},
		data: () => ({}),
		computed: {
			nav1() {
				return this.$layout.navFooter[0] ? [this.$layout.navFooter[0]] : [];
			},

			nav2() {
				return this.$layout.navFooter[1] ? [this.$layout.navFooter[1]] : [];
			},

			nav3() {
				return this.$layout.navFooter[2] ? [this.$layout.navFooter[2]] : [];
			},

			nav4() {
				return this.$layout.navFooter[3] ? this.$layout.navFooter.slice(3) : [];
			},
		},
		methods: {},
	};
