import Vue from 'vue';

Vue.mixin({
	computed: {
		$user() {
			return this.$store.state.user;
		},
		$filtered() {
			return this.$store.state.filtered;
		},
		$filters() {
			return this.$store.state.filters;
		},
		$catalog() {
			return this.$store.state.catalog;
		},
		$layout() {
			return this.$store.state.layout;
		},
		$favorites() {
			return this.$store.state.favorites;
		},
		$product() {
			return this.$store.state.product;
		},
		$intersection() {
			return this.$store.state.intersection;
		},
		$siteLinks() {
			return this.$store.state.siteLinks;
		},
	},
});
