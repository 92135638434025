
	import _prop from 'vue-types';
	import { mapGetters } from 'vuex';
	import AppTags from '~components/_app/app-tags/app-tags.vue';

	export default {
		name: 'search-form',
		components: { AppTags },
		props: {
			variant: _prop.oneOf(['plain', 'modal']).def('modal'),
		},
		emits: ['submit'],
		data: () => ({
			searchQuery: '',
		}),
		computed: {
			...mapGetters({
				suggestions: 'layout/searchSuggestions',
			}),

			cItems() {
				return [...this.suggestions(this.searchQuery)].slice(0, 16);
			},

			isModal() {
				return this.variant === 'modal';
			},
		},
		methods: {
			onSubmit() {
				this.$router.push({
					path: this.$siteLinks.search,
					query: {
						...this.$route.query,
						q: this.searchQuery,
					},
				});
				this.$emit('submit');
			},
		},
		async mounted() {
			await this.$nextTick();
			this.$el.querySelector('input')?.focus();
		},
	};
