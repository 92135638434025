
	import _prop from 'vue-types';
	import { TransitionFade, TransitionSlide } from '@morev/vue-transitions';

	export default {
		TransitionFade,
		TransitionSlide,
		// eslint-disable-next-line vue/multi-word-component-names
		name: 'modal',
		inheritAttrs: false,
		components: {},
		props: {
			variant: _prop.oneOf(['center', 'mobile', 'info']).def('center'),
			modalClass: _prop.oneOfType([String, Array]).def(''),
		},
		data: () => ({}),
		computed: {
			cClassnames() {
				return {
					root: (ctx) => this.b(null),
					overlay: (ctx) => this.b('overlay'),
					loader: (ctx) => this.b('loader'),
					wrapper: (ctx) => this.b('wrapper', {
						variant: this.variant,
					}),
					element: (ctx) => {
						const position = ['mobile', 'info'].includes(this.variant)
							? 'bottom'
							: false;

						const extraModalClasses = Array.isArray(this.modalClass)
							? this.modalClass
							: [this.modalClass];

						return [
							this.b('element', { position }),
							...extraModalClasses,
						];
					},
				};
			},
		},
		methods: {},
	};
