/* eslint-disable */

import { SmoothScroll as Instance } from '@morev/smooth-scroll';

const scroller = new Instance({"fixedElements":{"y":{"start":["[data-fixed-header]"]}}});

//

export default (ctx, inject) => {
	inject('scroller', scroller);
	inject('scrollTo', scroller.to.bind(scroller));
};

//
