
	import _prop from 'vue-types';

	export default {
		name: 'header-phone-block',
		inheritAttrs: false,
		components: {},
		props: {
			phone: _prop.string,
			phoneClean: _prop.string,
			descriptionTop: _prop.string,
			// useModal: _prop.bool.def(false),
		},
		data: () => ({}),
		computed: {},
		methods: {},
		mounted() {
			this.$modals.register('feedback');
		},
	};
