
	import _prop from 'vue-types';
	import { mapGetters } from 'vuex';
	import BaseContainer from '~components/_base/base-container/base-container.vue';
	import { getInfoMessageIds, setInfoMessageIds } from '~/shared/info-messages.js';

	export default {
		name: 'the-info-message',
		components: { BaseContainer },
		props: {
			data: _prop.shape({
				id: _prop.integer,
				content: _prop.string,
			}),
		},
		data: (vm) => ({
			isExpanded: true,

		}),
		computed: {
			...mapGetters({
				userToken: 'user/token',
			}),

			shouldRender() {
				return !!this.data?.content
					&& !getInfoMessageIds(this.$cookies).includes(this.data.id);
			},
		},
		watch: {},
		methods: {
			async onClose() {
				this.isExpanded = false;
				const { id } = this.data;
				const response = await this.$api.post('/site-info-message/', { id, token: this.userToken });
				const backendIds = response?.ids || [];
				const newIds = [...backendIds, id];
				setInfoMessageIds(this.$cookies, newIds);
			},
		},
	};
