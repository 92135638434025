
	import _prop from 'vue-types';

	export default {
		name: 'footer-privacy',
		components: {},
		props: {
			items: _prop.array,
		},
		data: () => ({}),
		computed: {
			cItems() {
				return this.items.map((item) => ({
					component: item.link === this.$route.path ? 'span' : 'a',
					attrs: item.link === this.$route.path ? {} : { href: item.link },
					code: item.code,
					label: item.label,
				}));
			},
		},
		methods: {},
	};
