import Vue from 'vue';
import VueScreen from 'vue-screen';

export default function({ app }) {
  let options = {"phonePortrait":320,"phoneStandard":360,"phoneLandscape":480,"tabletPortrait":768,"tabletLandscape":1024,"desktop":1280,"desktopMiddle":1440,"desktopHd":1670,"desktop2K":1920,"desktop4K":2560,"breakpointsOrder":["phonePortrait","phoneStandard","phoneLandscape","tabletPortrait","tabletLandscape","desktop","desktopMiddle","desktopHd","desktop2K","desktop4K"],"lessPhonePortrait":function anonymous(screen
) {
return !screen.phonePortrait
},"morePhonePortrait":function anonymous(screen
) {
return screen.phonePortrait
},"lessPhoneStandard":function anonymous(screen
) {
return !screen.phoneStandard
},"morePhoneStandard":function anonymous(screen
) {
return screen.phoneStandard
},"lessPhoneLandscape":function anonymous(screen
) {
return !screen.phoneLandscape
},"morePhoneLandscape":function anonymous(screen
) {
return screen.phoneLandscape
},"lessTabletPortrait":function anonymous(screen
) {
return !screen.tabletPortrait
},"moreTabletPortrait":function anonymous(screen
) {
return screen.tabletPortrait
},"lessTabletLandscape":function anonymous(screen
) {
return !screen.tabletLandscape
},"moreTabletLandscape":function anonymous(screen
) {
return screen.tabletLandscape
},"lessDesktop":function anonymous(screen
) {
return !screen.desktop
},"moreDesktop":function anonymous(screen
) {
return screen.desktop
},"lessDesktopMiddle":function anonymous(screen
) {
return !screen.desktopMiddle
},"moreDesktopMiddle":function anonymous(screen
) {
return screen.desktopMiddle
},"lessDesktopHd":function anonymous(screen
) {
return !screen.desktopHd
},"moreDesktopHd":function anonymous(screen
) {
return screen.desktopHd
},"lessDesktop2K":function anonymous(screen
) {
return !screen.desktop2K
},"moreDesktop2K":function anonymous(screen
) {
return screen.desktop2K
},"lessDesktop4K":function anonymous(screen
) {
return !screen.desktop4K
},"moreDesktop4K":function anonymous(screen
) {
return screen.desktop4K
}};
  if (typeof options === 'object' && Object.keys(options).length === 0) {
    options = '';
  }

  Vue.use(VueScreen, options);
  app.$screen = Vue.prototype.$screen;
};
