
	import _prop from 'vue-types';

	export default {
		name: 'the-version-checker',
		components: {},
		props: {},
		data: (vm) => ({
			isVisible: false,
			timer: null,
			version: null,
		}),
		computed: {},
		watch: {
			async isVisible(isVisible) {
				if (!isVisible) return;
				const result = await this.$modals.open('notification', {
					bind: {
						title: this.$t('we-updated-the-site'),
						text: `${this.$t('message')}`,
						actions: [
							{ text: this.$t('refresh-page'), closeWith: 'reload', variant: 'primary' },
						],
					},
				});

				if (result === 'reload') window.location.reload();
				this.isVisible = false;
			},
		},
		methods: {
			async checkVersion() {
				this.$axios.$get('/app-version.txt')
					.then(version => {
						(this.version === null) && (this.version = version);
						(this.version !== version) && (this.isVisible = true);
						this.version = version;
					})
					.catch(() => {});
			},
		},
		mounted() {
			if (process.env.NODE_ENV !== 'production') return;
			this.timer = setInterval(this.checkVersion, 15000);
			this.checkVersion();
		},
		beforeDestroy() {
			clearInterval(this.timer);
		},
	};
