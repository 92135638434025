import ApiDecorator from '@runway-frontend/nuxt-api/src/api-decorator.mjs';

// For correct subdomains work
ApiDecorator.addConstructorDecorator((context) => {
	process.client && context.$axios.setBaseURL(window.location.origin);
});

// Add parameters to composite query
ApiDecorator.addCompositeDecorator((data, context) => {
	const { lang, currency } = context.store.state.user;
	const { cityId } = context.$pinia.state.value.global ?? {};

	const host = process.server
		? context.req?.headers?.host?.replace(/:\d*$/, '')
		: window.location.hostname;

	return { cityId, lang, currency, host };
});

// Add `path` and `query_params` to each request
ApiDecorator.addRequestDecorator({ print: false }, (entry, context) => {
	entry.params.path = context.route.path;
	entry.params.query_params = context.route.query;
	return entry;
});

ApiDecorator.addResponseDecorator({ route: 'site-contacts' }, ({ response }) => {
	if (!response) return;
	(!response.apps.rustore) && (response.apps.rustore = '#');
	(!response.apps.appgallery) && (response.apps.appgallery = '#');

	response.qrCode ||= {
		src: '/images/qr-uflor.svg',
		alt: 'QR-код для скачивания приложения UFlor',
	};

	return response;
});

export default ApiDecorator;
