import { helpers } from 'vuelidate/lib/validators/index.js';
import { parseDate } from '~/scripts/helpers';

export default (ctx) => ({
	minDate: (min) => (val) => {
		min = +parseDate(min);
		const valStamp = +parseDate(val);
		return !helpers.req(val) || valStamp >= min;
	},

	maxDate: (max) => (val) => {
		max = +parseDate(max);
		const valStamp = +parseDate(val);
		return !helpers.req(val) || valStamp <= max;
	},

	betweenDate: (min, max) => (val) => {
		[min, max] = [min, max].map(i => +parseDate(i));
		const valStamp = +parseDate(val, 'ru');
		return !helpers.req(val) || (valStamp <= max && valStamp >= min);
	},

	regex: (pattern) => (val) => {
		return !helpers.req(val) || !!String(val).match(new RegExp(pattern));
	},

	toBeTrue: (v) => v === true,

	callback: (url) => async (val) => {
		if (!helpers.req(val)) return true;
		const data = new FormData();
		data.append('value', val);

		return (await ctx.$axios.$post(url, data, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		// eslint-disable-next-line unicorn/no-await-expression-member
		})).result;
	},
});
