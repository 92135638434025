// TODO: lazy backgrounds, iframes, videos?
import Vue from 'vue';
import { isFalsy } from '~/scripts/helpers';

const defaultObserverOptions = {
	root: null,
	threshold: `0`,
	rootMargin: `75% 75%`,
};

const observersStore = new WeakMap();

const loadImage = (target) => {
	const tagName = target.tagName.toLowerCase();
	const image = (tagName === 'picture')
		? target.querySelector('img')
		: target;

	image.addEventListener(`load`, () => {
		target.classList.add(`is-loaded`);
		target.dispatchEvent(new CustomEvent('lazy-load'));
	});
	// eslint-disable-next-line no-console
	image.addEventListener(`error`, () => console.log(`error`));

	if (tagName === 'picture') {
		target.querySelectorAll('source').forEach(source => {
			if (source.dataset.srcset) source.srcset = source.dataset.srcset;
		});
	}

	if (target.dataset.srcset) target.srcset = target.dataset.srcset;
	if (target.dataset.src) target.src = target.dataset.src;
};

const lazyFunc = (el, value) => {
	// Skip if no need to load lazy
	if (!value || (value.active !== undefined && isFalsy(value.active))) return;
	const observerOptions = value.observerOptions || defaultObserverOptions;

	const handleIntersect = (entries, observer) => {
		entries.forEach(entry => {
			if (entry.isIntersecting) {
				loadImage(entry.target);
				observer.unobserve(entry.target);
			}
		});
	};

	const createObserver = () => {
		const inStore = observersStore.has(observerOptions);
		const observer = inStore
			? observersStore.get(observerOptions)
			: new IntersectionObserver(handleIntersect, observerOptions);

		!inStore && observersStore.set(observerOptions, observer);
		observer.observe(el);
	};

	window.IntersectionObserver && createObserver();
	!window.IntersectionObserver && loadImage(el);
};

Vue.directive('lazy-load', {
	inserted: (el, { value }) => {
		lazyFunc(el, value);
	},
});
