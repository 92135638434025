
	import _prop from 'vue-types';

	const FormInput = () => import('~components/_form/form-input/form-input.vue');
	const FormCheckbox = () => import('~components/_form/form-checkbox/form-checkbox.vue');

	export default {
		name: 'form-field',
		inheritAttrs: false,
		components: { FormInput, FormCheckbox },
		props: {
			hash: _prop.string,
			name: _prop.string,
			type: _prop.string,
			value: _prop.oneOfType([String, Boolean, Number]),
			checked: _prop.bool,
			checkboxVisible: _prop.bool.def(true),
			label: _prop.string,
			placeholder: _prop.string,
			autocomplete: _prop.string.def('off'),
			readonly: _prop.bool.def(false),
			disabled: _prop.bool.def(false),
			mask: _prop.string,
			items: _prop.array,
			errors: _prop.array,
			modifiers: _prop.object,
			innerModifiers: _prop.object,
			validators: _prop.object,
			vuelidate: _prop.object,
			validatorsErrors: _prop.array,
		},
		emits: ['input', 'change', 'focus', 'blur'],
		data: () => ({}),
		computed: {
			model: {
				get() {
					if (this.type === 'checkbox') {
						return this.checked;
					}

					return this.value || '';
				},

				set(v) {
					if (this.type === 'checkbox') {
						return this.$emit('change', v);
					}
					this.$emit('input', v);
				},
			},

			hasErrors() {
				return !!(
					this.errors?.length
					|| (this.vuelidate.$dirty && this.validatorsErrors.length && !this.vuelidate.$pending)
				);
			},

			allErrors() {
				return [
					...(this.vuelidate.$dirty ? this.validatorsErrors : []),
					...(this.errors || []),
				];
			},

			isTextInput() {
				return ['text', 'textarea', 'phone', 'email', 'numeric', 'date', 'password'].includes(this.type);
			},

			hasVuelidate() {
				return !!Object.keys(this.vuelidate || {}).length;
			},

			computedModifiers() {
				return {
					...this.modifiers,
					hidden: this.isHiddenInput,
					filled: !!this.value,
					valid: this.hasVuelidate ? !this.vuelidate.$invalid : false,
					error: this.hasErrors,
				};
			},

			isRequired() {
				return !!(this.validators?.required && this.showRequired);
			},
		},
		methods: {},
	};
