
	export default {
		name: 'formulate-input-box',
		props: {
			context: {type: Object, required: true},
		},
		computed: {
			usesDecorator() {
				return this.$formulate.options.useInputDecorators;
			},
		},
	};
