
	import _prop from 'vue-types';
	import { mapGetters } from 'vuex';
	import { debounce, getWindowScroll } from '@morev/utils';
	import ControlButton from '~components/control-button/control-button.vue';
	import BaseContainer from '~components/_base/base-container/base-container.vue';
	import { mapStores } from 'pinia';
	import { useEntityStore } from '~stores/index';

	export default {
		name: 'the-interactions',
		components: { BaseContainer, ControlButton },
		props: {},
		data: () => ({
			isScrolledDown: false,
			isScrolledDown200: false,
		}),
		computed: {
			...mapStores(useEntityStore),

			...mapGetters({
				favoritesCount: 'favorites/count',
				cartCount: 'cart/count',
			}),
			areControlButtonsNeeded() {
				return !['page-cart', 'page-checkout'].includes(this.entityStore.current.entity);
			},
		},
		methods: {
			checkScrollTop() {
				const scrollTop = getWindowScroll();
				this.isScrolledDown = scrollTop > 0;
				this.isScrolledDown200 = scrollTop > 200;
			},
		},
		mounted() {
			window.addEventListener('scroll', this.checkScrollTop);
			this.checkScrollTop();
		},
		beforeDestroy() {
			window.removeEventListener('scroll', this.checkScrollTop);
		},
		created() {
			this.checkScrollTop = debounce(this.checkScrollTop, 20);
		},
	};
