import Vue from 'vue';
import VueFormulate from '@braid/vue-formulate';
import { ru } from '@braid/vue-formulate-i18n';

import MoreFormulate from './more-vue-formulate/index.js';

Vue.use(VueFormulate, {
	plugins: [ru, MoreFormulate],
	locale: 'ru',
	locales: {
		ru: {
			required: ({ name }) => `Пожалуйста, заполните ${name}`,
		},
	},
	validationNameStrategy: (vm) => {
		if (vm.validationName) return vm.validationName;
		if (vm.label) return vm.label.toLowerCase();
		return vm.name || vm.type || 'поле';
	},
	rules: {
		phone: ({ value, ...rest }) => (value.match(/\d/g)?.length || 1) > 7,
	},
});
