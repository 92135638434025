
	import _prop from 'vue-types';
	import { mapGetters } from 'vuex';

	import CommonSocialLinks from '~components/_common/common-social-links/common-social-links.vue';

	export default {
		name: 'footer-social',
		components: { CommonSocialLinks },
		props: {},
		data: () => ({}),
		computed: {
			...mapGetters({
				social: 'layout/social',
			}),
		},
		methods: {},
	};
