
	import _prop from 'vue-types';
	import BaseButton from '~components/_base/base-button/base-button.vue';

	export default {
		name: 'page-message',
		components: { BaseButton },
		props: {
			tag: _prop.string.def('h1'),
			title: _prop.string.isRequired,
			description: _prop.oneOfType([String, Array, Object]),
			options: _prop.object,
			button: _prop.shape({
				link: _prop.string,
				text: _prop.string,
			}),
		},
		data: () => ({}),
		computed: {
			cOptions() {
				return { align: 'center', useButton: true, ...this.options };
			},

			isButtonNeeded() {
				return !!(this.button?.text && this.button?.link && this.cOptions.useButton);
			},
		},
		methods: {},
		render() {
			const description = this.description
				? (
					typeof this.description === 'string'
						? <div class={this.b('description')} domPropsInnerHTML={ this.description }></div>
						: <div class={this.b('description')}>{ this.description }</div>
				)
				: '';

			const button = this.isButtonNeeded
				? (
					<BaseButton class={this.b('button')} link={this.button.link}>
						{ this.button.text }
					</BaseButton>
				)
				: '';

			const Component = this.tag;

			const preTitle = this.$slots['pre-title'] || '';

			return (
				<div class={this.b(null, { align: this.cOptions.align })}>
					{ preTitle }
					{ this.title && <Component class={this.b('title')} domPropsInnerHTML={ this.title } /> }
					{ description }
					{ button }
				</div>
			);
		},
	};
