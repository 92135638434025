
	import _prop from 'vue-types';

	export default {
		name: 'catalog-nav-secondary',
		components: {},
		props: {
			items: _prop.array,
			mode: _prop.oneOf(['hover', 'click']).def('hover'),
		},
		emits: ['index-change'],
		data: (vm) => ({
			activeIndex: 0,
		}),
		computed: {},
		watch: {},
		methods: {
			onPointerEnter(index) {
				if (this.mode === 'click') return;
				this.activeIndex = index;
				this.$emit('index-change', index);
			},

			onClick(index) {
				this.activeIndex = index;
				this.$emit('index-change', index);
			},
		},
	};
