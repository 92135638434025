
	import _prop from 'vue-types';

	export default {
		name: 'search-animated',
		components: {},
		props: {
			active: _prop.bool.def(false),
		},
		emits: ['click'],
		data: () => ({}),
		computed: {},
		methods: {},
	};
