
	import _prop from 'vue-types';
	import { FocusTrap } from 'focus-trap-vue';
	import CommonCrossButton from '~components/_common/common-cross-button/common-cross-button.vue';
	import SearchForm from '~components/search-form/search-form.vue';
	import { mapStores } from 'pinia';
	import { useEntityStore } from '~stores/index';

	export default {
		name: 'header-search',
		components: { FocusTrap, CommonCrossButton, SearchForm },
		props: {},
		emits: ['close'],
		data: () => ({}),
		computed: {
			...mapStores(useEntityStore),
		},
		watch: {},
		methods: {},
		async mounted() {
			this.$refs?.focusTrap?.activate();
		},
		beforeDestroy() {
			this.$refs?.focusTrap?.deactivate();
		},
	};
