
	import _prop from 'vue-types';
	import BasePage from '~components/_base/base-page/base-page.vue';
	import TheCare from '~components/_the/the-care/the-care.vue';
	import Section404 from './_section-404/section-404.vue';

	export const asyncData = async (ctx, params) => {};

	export default {
		name: 'page-404',
		components: { BasePage, Section404, TheCare },
		props: {
			error: _prop.object,
		},
		head() {
			return this.error.head;
		},
	};
