
	import _prop from 'vue-types';
	import { mapGetters } from 'vuex';

	import BaseSection from '~components/_base/base-section/base-section.vue';
	import BaseContainer from '~components/_base/base-container/base-container.vue';
	import CommonSocialLinks from '~components/_common/common-social-links/common-social-links.vue';

	export default {
		name: 'the-care-mobile',
		components: {
			BaseSection,
			BaseContainer,
			CommonSocialLinks,
		},
		props: {},
		data: () => ({}),
		computed: {
			...mapGetters({
				mail: 'layout/mail',
				phones: 'layout/phones',
				social: 'layout/social',
			}),

			computedPhones() {
				return [...this.phones].reverse();
			},
		},
		methods: {},
		mounted() {
			this.$modals.register('feedback');
			setTimeout(() => this.$el.classList.add('is-mounted'), 20);
		},
	};
