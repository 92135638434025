import Vue from 'vue';

import { setInfoMessageIds } from '~/shared/info-messages.js';

export const state = () => ({
	isInitialized: false,
	token: null,
	isLogged: false,
	currency: null,
	lang: null,
	points: null,
	personalAreaLink: null,
});

export const mutations = {
	SET_INITIALIZED: (state, value) => state.isInitialized = value,
	SET_DATA: (state, data) => Object.keys(data).forEach(key => Vue.set(state, key, data[key])),
};

export const actions = {
	async init({ commit, state }) {
		if (state.isInitialized) return;

		const data = await this.$api.get('/user-info');

		const seenNotificationIds = data.seenNotificationIds || [];
		setInfoMessageIds(this.$cookies, seenNotificationIds);

		commit('SET_DATA', data);
		commit('SET_INITIALIZED', true);
		await this.app.i18n.setLocale(data.lang || 'ru');
	},

	async refetch({ commit }) {
		commit('SET_LOADING', true);

		const data = await this.$api.get('/user-info');
		commit('SET_DATA', data);

		commit('SET_LOADING', false);
	},

	async logout() {
		this.$broadcastChannel?.postMessage('auth');
		return this.$api.get('/user-logout');
	},
};

export const getters = {
	isInitialized: (state) => state.isInitialized,
	isLogged: (state) => state.isLogged,
	points: (state) => state.points,
	token: (state) => state.token,
};
