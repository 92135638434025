
	import _prop from 'vue-types';
	import { mapGetters } from 'vuex';
	import { getWindowScroll, isEmpty } from '@morev/utils';

	import BaseSection from '~components/_base/base-section/base-section.vue';
	import BaseContainer from '~components/_base/base-container/base-container.vue';
	import CommonLogo from '~components/_common/common-logo/common-logo.vue';
	import CommonSandwichButton from '~components/_common/common-sandwich-button/common-sandwich-button.vue';
	import SearchAnimated from '~components/search-animated/search-animated.vue';
	import FixedFilters from '~components/fixed-filters/fixed-filters.vue';
	import ControlButton from '~components/control-button/control-button.vue';
	import AppSelector from '~components/_app/app-selector/app-selector.vue';
	import AppArrowButton from '~components/_app/app-arrow-button/app-arrow-button.vue';
	import CommonSocialLinks from '~components/_common/common-social-links/common-social-links.vue';
	import SafeNuxtLink from '~components/safe-nuxt-link/safe-nuxt-link.vue';
	import { mapStores } from 'pinia';
	import { useEntityStore } from '~stores/index.js';
	import CatalogNavPrimary from '~components/catalog-nav/_catalog-nav-primary/catalog-nav-primary.vue';
	import CatalogNavSecondary from '~components/catalog-nav/_catalog-nav-secondary/catalog-nav-secondary.vue';
	import CatalogNavTertiary from '~components/catalog-nav/_catalog-nav-tertiary/catalog-nav-tertiary.vue';
	import TheCareMobile from '../the-care-mobile/the-care-mobile.vue';
	import HeaderNavMobile from './_header-nav-mobile/header-nav-mobile.vue';
	import HeaderPhoneBlock from './_header-phone-block/header-phone-block.vue';
	import HeaderNav from './_header-nav/header-nav.vue';
	import HeaderSearch from './_header-search/header-search.vue';

	const INFORMATION_NAV_KEY = 'info';

	export default {
		name: 'the-header',
		refs: ['$navMobile'],
		components: {
			BaseSection,
			BaseContainer,
			CommonLogo,
			HeaderNav,
			HeaderSearch,
			CommonSandwichButton,
			HeaderPhoneBlock,
			TheCareMobile,
			HeaderNavMobile,
			SearchAnimated,
			FixedFilters,
			ControlButton,
			AppSelector,
			AppArrowButton,
			CommonSocialLinks,
			SafeNuxtLink,
			CatalogNavPrimary,
			CatalogNavSecondary,
			CatalogNavTertiary,
		},
		props: {},
		data() {
			return {
				isMounted: false,
				isNavOpened: false,
				areContactsOpened: false,
				isSearchOpened: false,
				isSearchExpanded: false,
				isScrolledDown: false,
				isShifted: false,
				//
				currentScroll: 0,
				lastScrollDirection: 'up',
				lastDifferentDirectionScroll: 0,
				//
				activeRootIndex: null,
				activeSecondaryKey: null,
			};
		},
		computed: {
			...mapStores(useEntityStore),

			...mapGetters({
				phones: 'layout/phones',
				social: 'layout/social',
				favoritesCount: 'favorites/count',
			}),

			...mapGetters('user', ['isLogged']),

			cCatalogAndInfoItems() {
				const catalogNav = this.$layout.navCatalog.items.map((item, index) => {
					const tertiary = (item.secondary ?? []).reduce((acc, secondaryItem) => {
						acc.push(secondaryItem);
						return acc;
					}, []);

					return {
						enlarged: true,
						key: index,
						label: item.label,
						primary: item.primary,
						secondary: item.secondary,
						hasPrimaryPart: !isEmpty(item.primary.items) || !isEmpty(item.primary.link),
						hasSecondaryPart: !isEmpty(item.secondary),
						nestedVisible: index === this.activeRootIndex,
						tertiary,
					};
				});

				const informationNav = (() => {
					if (isEmpty(this.$layout.navMain)) return null;

					return {
						key: INFORMATION_NAV_KEY,
						label: this.$t('information'),
						primary: {
							items: this.$layout.navMain.map((item) => ({ label: item.label, link: item.link })),
						},
						hasPrimaryPart: true,
						hasSecondaryPart: false,
						nestedVisible: this.activeRootIndex === INFORMATION_NAV_KEY,
						tertiary: null,
					};
				})();

				return [
					...catalogNav,
					informationNav,
				].filter(Boolean);
			},

			cNavShort() {
				return this.$layout.navShort.map((item) => ({
					component: item.link === this.$route.path ? 'span' : 'a',
					attrs: item.link === this.$route.path ? {} : { href: item.link },
					label: item.label,
					icon: item.icon,
					code: item.code,
				}));
			},

			cVariant() {
				return this.entityStore.current.pageInfo?.hero?.variant ?? 'small';
			},

			moreThanOnePhoneBlock() {
				return this.phones.length > 1;
			},

			cExtraPhoneBlocks() {
				return this.phones.slice(1);
			},

			isSubcategoryActive() {
				if (process.server || !this.isMounted || !this.$refs.$navMobile) return false;
				return this.activeRootIndex !== null || !!this.activeSecondaryKey;
			},
		},
		watch: {
			'$route.path': {
				handler() {
					this.isNavOpened = false;
					this.isSearchOpened = false;
					this.isSearchExpanded = false;
				},
				immediate: true,
			},
			'isNavOpened': {
				handler(to) {
					if (process.server) return;
					to && (document.body.style.setProperty('overflow', 'hidden'));
					!to && (document.body.style.removeProperty('overflow'));
				},
				immediate: true,
			},
			isShifted() {
				this.isSearchOpened = false;
				this.isSearchExpanded = false;
			},
		},
		methods: {
			scrollActions() {
				if (this.$screen.desktop) {
					this.isShifted = false;
					return;
				}

				const scroll = getWindowScroll('y');
				this.isScrolledDown = scroll > 0;

				const delta = scroll - this.currentScroll;

				if (delta > 0) {
					if (this.lastScrollDirection === 'up') {
						this.lastDifferentDirectionScroll = scroll;
					}

					this.lastScrollDirection = 'down';
				}

				if (delta <= 0) {
					if (this.lastScrollDirection === 'down') {
						this.lastDifferentDirectionScroll = scroll;
					}

					this.lastScrollDirection = 'up';

					if (Math.abs(this.lastDifferentDirectionScroll - scroll) < 100) {
						this.currentScroll = scroll;
						return;
					}
				}

				this.currentScroll = scroll;
				const minimalHeight = this.cVariant === 'large'
					? 370 // `the-hero` height
					: 100;
				this.isShifted = scroll > minimalHeight && delta > 0;
			},

			onKeyDown(e) {
				if (e.code !== 'Escape') return;
				this.isSearchOpened = false;
			},

			onNavClick() {
				this.isSearchOpened = false;
				this.isNavOpened = !this.isNavOpened;

				if (!this.isNavOpened) {
					this.activeRootIndex = -1;
					this.reset();
					this.$refs.mobileBodyRef.scrollTop = 0;
				}
			},

			onSearchClick() {
				this.isNavOpened = false;
				const ANIMATONS_DELAY = this.isScrolledDown ? 0 : 250;

				if (this.isSearchExpanded) {
					this.isSearchExpanded = false;
					setTimeout(() => {
						this.isSearchOpened = false;
					}, ANIMATONS_DELAY);
				} else {
					this.isSearchOpened = true;
					setTimeout(() => {
						this.isSearchExpanded = true;
					}, ANIMATONS_DELAY);
				}
			},

			async closeSubcategory() {
				if (this.activeSecondaryKey) {
					this.$refs.tertiaryRef.forEach((ref) => (ref.$el.scrollTop = 0));
					this.activeSecondaryKey = null;
				} else {
					this.$refs.nestedRef.forEach((ref) => (ref.scrollTop = 0));
					this.activeRootIndex = null;
				}
			},

			async reset() {
				await this.closeSubcategory();
				await this.closeSubcategory();
			},

			onMobileOverlayClick() {
				this.isNavOpened = false;
				this.reset();
			},
		},

		beforeMount() {
			this.isScrolledDown = getWindowScroll('y') > 0;
		},

		mounted() {
			window.addEventListener('keydown', this.onKeyDown);
			['resize', 'orientationchange', 'scroll'].forEach(ev => window.addEventListener(ev, this.scrollActions));
			this.isMounted = true;

			this.$mitt.on('catalog-nav.click', (index) => {
				this.activeRootIndex = index;
				this.isNavOpened = true;
				this.isShifted = false;
			});
		},

		beforeDestroy() {
			window.removeEventListener('keydown', this.onKeyDown);
			['resize', 'orientationchange', 'scroll'].forEach(ev => window.removeEventListener(ev, this.scrollActions));
		},
	};
