import Vue from 'vue';

const random = () => Math.random().toString(36).slice(-5);

class Notifications {
	constructor() {
		this.list = [];
	}

	add(data, duration = 3000) {
		const obj = { __id: random(), ...data };
		this.list.push(obj);
		setTimeout(() => (this.list = this.list.filter(i => i !== obj)), duration);
	}
}

export default (ctx, inject) => {
	inject('notifications', Vue.observable(new Notifications()));
};
