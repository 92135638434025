
	import _prop from 'vue-types';
	import PageMessage from '~components/page-message/page-message.vue';

	import BaseContainer from '~components/_base/base-container/base-container.vue';
	import BaseImg from '~components/_base/base-img/base-img.vue';
	import BaseSection from '~components/_base/base-section/base-section.vue';

	export default {
		name: 'section-404',
		components: { BaseSection, BaseContainer, BaseImg, PageMessage },
		props: {},
		data: () => ({}),
		computed: {},
		methods: {},
	};
