
	import { mapGetters } from 'vuex';
	import _prop from 'vue-types';

	export default {
		name: 'app-selector-cities',
		components: {},
		props: {},
		data: () => ({
			loopLetter: null,
		}),
		computed: {
			...mapGetters({
				mainCities: 'layout/mainCities',
				restCities: 'layout/restCities',
			}),

			computedRestCities() {
				let prevLetter = null;
				return this.restCities
					.filter((city) => city.isVisible)
					.map(city => {
						let letter = null;
						if (city.title[0] !== prevLetter) {
							// eslint-disable-next-line no-multi-assign
							prevLetter = letter = city.title[0];
						}
						return { ...city, letter };
					});
			},
		},
		methods: {
			isFirstWithLetter(title, set = false) {
				if (title[0] === this.loopLetter) return false;

				set && (this.loopLetter = title[0]);
				return true;
			},
		},
	};
