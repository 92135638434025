import FormulateInputPassword from './components/formulate-input-password.vue';
import FormulateInputNumber from './components/formulate-input-number.vue';
import FormulateInputText from './components/formulate-input-text.vue';

import FormulateInputGroup from './components/formulate-input-group.vue';
import FormulateInputBox from './components/formulate-input-box.vue';

export default (formulateInstance) => {
	formulateInstance.extend({
		components: {
			FormulateInputGroup,
		},
		library: {
			text: {
				classification: 'text',
				component: FormulateInputText,
				slotProps: {
					component: [
						'imask',
						'unmaskModel',
					],
				},
			},
			radio: {
				classification: 'box',
				component: FormulateInputBox,
				slotProps: {
					component: [
						'groupClasses',
					],
				},
			},
			checkbox: {
				classification: 'box',
				component: FormulateInputBox,
				slotProps: {
					component: [
						'groupClasses',
					],
				},
			},
			password: {
				classification: 'text',
				component: FormulateInputPassword,
			},
			number: {
				classification: 'text',
				component: FormulateInputNumber,
				slotProps: {
					component: [
						'min',
						'max',
						'step',
						'precision',
						'strictStep',
					],
				},
			},
		},
	});
};
