import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat.js';
import { numberFormat } from '@morev/utils';

dayjs.extend(customParseFormat);

const btoa = (str) => ((str instanceof Buffer) ? str : Buffer.from(str.toString(), 'binary')).toString('base64');

export const rub = () => `₽`;

export const parseDate = async (str, format = 'ISO') => {
	if (!str) return null;
	format = format.toLowerCase();
	if (Number.isInteger(str)) return new Date(str);

	return (format === 'iso')
		? dayjs(str).toDate()
		: dayjs(str, 'DD.MM.YYYY').toDate();
};

/**
 * Gets inline base64 image with given size.
 * Used for generating image placeholders till image load
 *
 * @param     {Array | number}   size   Sizes as array or one number for square placeholder
 *
 * @returns   {string}                  Base64 string containing SVG with given sizes
 */
export const getPixel = (size) => {
	const [w, h] = Array.isArray(size)
		? [size[0], size[1] ?? size[0]]
		: [size, size];
	const svg = `<svg width="${w}" height="${h}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 ${w} ${h}"></svg>`;
	return `data:image/svg+xml;base64,${btoa(svg)}`;
};

// eslint-disable-next-line unicorn/no-unsafe-regex
export const formatThousands = (v) => {
	const afterDot = v % 1 === 0 ? 0 : 1;
	return numberFormat(v, afterDot, '.', ' ');
};

export const isFalsy = (v) => v === false || v === null || v === undefined;

export const getMonthName = (monthIndex, locale = 'ru') => {
	const names = [
		{ ru: 'января', en: 'January' },
		{ ru: 'февраля', en: 'February' },
		{ ru: 'марта', en: 'March' },
		{ ru: 'апреля', en: 'April' },
		{ ru: 'мая', en: 'May' },
		{ ru: 'июня', en: 'June' },
		{ ru: 'июля', en: 'July' },
		{ ru: 'августа', en: 'August' },
		{ ru: 'сентября', en: 'September' },
		{ ru: 'октября', en: 'October' },
		{ ru: 'ноября', en: 'November' },
		{ ru: 'декабря', en: 'December' },
	];
	return names[monthIndex][locale];
};

export const toReadableDate = (date, locale = 'ru') => {
	const semantic = {
		today: { ru: 'сегодня', en: 'today' },
		tomorrow: { ru: 'завтра', en: 'tomorrow' },
	};
	if (date.semantic) return semantic[date.semantic][locale];

	const dateObj = dayjs(date.date).toDate();
	const day = dateObj.getDate().toString();
	const month = getMonthName(dateObj.getMonth());

	return `${day} ${month}`;
};

// При GET-параметре `only_content=Y` не нужно выводить шапку, подвал и хлебные крошки
// https://bitrix24.realweb.ru/company/personal/user/103/tasks/task/view/55292/
export const isContentOnlyPage = (query) => query?.only_content === 'Y';
