
	// https://github.serafin.io/vuebar/
	import _prop from 'vue-types';

	export default {
		name: 'external-scrollbar',
		components: {},
		props: {
			maxHeight: _prop.string,
			classInner: _prop.string,
			options: _prop.object,
		},
		data: () => ({
			defaults: {
				preventParentScroll: true,
				el1Class: 'external-scrollbar',
				el1ScrollVisibleClass: 'external-scrollbar--visible',
				el1ScrollInvisibleClass: 'external-scrollbar--invisible',
				el1ScrollingClass: 'external-scrollbar--scrolling',
				el1ScrollingPhantomClass: 'external-scrollbar--phantom',
				el1DraggingClass: 'external-scrollbar--dragging',
				el1DraggingPhantomClass: 'external-scrollbar--dragging-phantom',
				el2Class: 'external-scrollbar__content',
				draggerClass: 'external-scrollbar__dragger',
				draggerStylerClass: 'external-scrollbar__dragger-styler',
			},
		}),
		computed: {
			vBarOptions() {
				return { ...this.defaults, ...this.options };
			},
		},
		methods: {},
	};
