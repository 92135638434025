
	import { isEmpty } from '@morev/utils';
	import _prop from 'vue-types';
	import BaseButton from '~components/_base/base-button/base-button.vue';
	import BaseImg from '~components/_base/base-img/base-img.vue';

	export default {
		name: 'catalog-nav-primary',
		components: { BaseButton, BaseImg },
		props: {
			items: _prop.array,
			link: _prop.object,
			enlargedPadding: _prop.bool.def(false),
		},
		data: (vm) => ({}),
		computed: {
			hasItems() {
				return !isEmpty(this.items);
			},

			hasLink() {
				return !isEmpty(this.link.label) && !isEmpty(this.link.link);
			},

			cItems() {
				return this.items.map((item, index) => {
					const isCurrent = item.link === this.$route.path;

					return {
						key: [index, isCurrent].join(','),
						component: isCurrent ? 'span' : 'a',
						attrs: isCurrent ? {} : { href: item.link },
						icon: item.icon,
						label: item.label,
					};
				});
			},
		},
		watch: {},
		methods: {
			hasItemIcon(icon) {
				return !isEmpty(icon?.src);
			},
		},
	};
